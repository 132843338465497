import { Link } from "react-router-dom";
import Timestamp from "react-timestamp";
import React, { useEffect, useState, useMemo } from "react";
import Loading from "react-fullscreen-loading";
import {
  getLocalStorageValue,
  formatDate,
  getLocalStorageObject,
  fetchUserRegulars,
  fetchAllRegularsDetails,
  countRegulars,
  fetchRegularDetails,
  toggleUserWorkingStatus,
  fetchUserEvents,
  updateResponseNumber,
  updateGoingNumber,
  updateNotGoingNumber,
  updateMaybeNumber,
  updatePhoneNumbers,
} from "utils/utils";
import { toast } from "react-toastify";

import { db } from "../../../../firebase/firebase";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';




const EventCard = ({ event, loading, setLoading, isLoading }) => {
  const { id, name, invited, going, maybe, notGoing } = event;
  const [regulars, setRegulars] = useState([]);
  const [regularDetails, setRegularDetails] = useState([]);
  const [totalRegulars, setTotalRegulars] = useState(0);
  const [responseCount, setResponseCount] = useState(0);
  

  const [goingCount, setGoingCount] = useState(0);
  const [maybeCount, setMaybeCount] = useState(0);
  const [notGoingCount, setNotGoingCount] = useState(0);
  const [totalResponses, setTotalResponses] = useState(0);
  const [responseRate, setResponseRate] = useState(0);
  const [events, setEvents] = useState([]);





  const userDetails = localStorage.getItem("userDetails");
  const userObject = userDetails ? getLocalStorageObject("userDetails") ?? null : null;







  const userId = getLocalStorageValue("userDetails", "phoneNumber");

  useEffect(() => {
    const fetchEvents = async () => {
      const events = await fetchUserEvents(userId);
      const allResponses =
        events.goingNumber + events.maybeNumber + events.notGoingNumber;

      setTotalResponses(allResponses);
      setGoingCount(events.goingNumber);
      setMaybeCount(events.maybeNumber);
      setNotGoingCount(events.notGoingNumber);

      // ... existing code ...
      if (allResponses > 0) {
      }
      return allResponses;
    };

    const fetchData = async () => {
      // {{ edit_2 }}
      setLoading(true);
      const allResponses = await fetchEvents(); // Await the fetchEvents call
      // console.log("All responses", allResponses);

      const calculateResponseRate = (responseNumber, invitesSent) => {
        if (invitesSent === 0) return 0;
        return Math.round((responseNumber / invitesSent) * 100); // Use invitesSent instead of totalResponses

      };

      if (allResponses > 0) {

        const responseRate = calculateResponseRate(
          allResponses, // Use allResponses instead of totalResponses
          totalRegulars
        );
        setResponseRate(responseRate);
        setLoading(false);
        // console.log("Response rate", responseRate);
        // console.log("Total res", allResponses);
      }
      setLoading(false);
    };

    fetchData(); // Call the new fetchData function
  }, [totalResponses, totalRegulars]); // Update dependencies
  useEffect(() => {

  }, [goingCount, maybeCount, notGoingCount]);

  const fetchAndLogUserData = async () => {
    try {
      setLoading(true);
      const userRegulars = await fetchUserRegulars(userId);
      setRegulars(userRegulars);
      // console.log("Regulars", userRegulars);clear
      setTotalRegulars(userRegulars.length);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchAndLogUserData();
  }, []);

  
  useEffect(() => {
    if (regulars.length > 0) {
      const fetchRegularsDetails = async () => {
        const regularDetails = await fetchAllRegularsDetails(regulars);
        setRegularDetails(regularDetails.mappedUsers);
      };
      fetchRegularsDetails();

      const count = countRegulars(regulars);
      setTotalRegulars(count);
    }
  }, [regulars]);

  useEffect(() => {}, [regularDetails]);

  // useEffect(() => {
  //   // Call the backend function when regularDetails changes
  //   if (regularDetails.length > 0) {
  //     if (regularDetails[0].phoneNumber) {
  //       // console.log("Phone number", regularDetails[0].phoneNumber);
  //       // console.log("Phone number sent to backend");
  //     }
  //   }
  // }, []);

  const sendPhoneNumberToBackend = async (phoneNumber) => {
    // Check if the phone number has already been processed

    try {
      const response = await fetch(
        "https://dp1xbesnil.execute-api.us-east-1.amazonaws.com/prod",
        {
          method: "POST",
          body: JSON.stringify({ phoneNumber }), // Use the phoneNumber parameter
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log("Backend Response:", data); // Log the response from the backend

      // Fetch user's events to find the latest created event
      const events = await fetchUserEvents(userId);
      const sanitizedNumber = `+${data.response.sanitizedNumber}`;

      //
      setResponseCount(events.responseNumber);
      const eventsPhoneNumbers = events.phoneNumbers;
      if (eventsPhoneNumbers.includes(sanitizedNumber)) {
        // console.log("Phone number already exists");
        return;
      } else {
        await updatePhoneNumbers(events.id, phoneNumber);
        // console.log("Response", data.response.response);
        if (
          data.response.response === "1" ||
          data.response.response === "yes" ||
          data.response.response === "Yes" ||
          data.response.response === "YES"
        ) {
          await updateResponseNumber(events.id, events.responseNumber); // {{ edit_2 }}
          await updateGoingNumber(events.id, events.goingNumber); // {{ edit_2 }}
        } else if (
          data.response.response === "3" ||
          data.response.response === "no" ||
          data.response.response === "No" ||
          data.response.response === "NO"
        ) {
          await updateResponseNumber(events.id, events.responseNumber); // {{ edit_2 }}
          await updateNotGoingNumber(events.id, events.notGoingNumber); // {{ edit_2 }}
        } else if (
          data.response.response === "2" ||
          data.response.response === "maybe" ||
          data.response.response === "Maybe" ||
          data.response.response === "MAYBE"
        ) {
          await updateResponseNumber(events.id, events.responseNumber); // {{ edit_2 }}
          await updateMaybeNumber(events.id, events.maybeNumber); // {{ edit_2 }}
        }
      }
    } catch (error) {
      console.error("Error sending phone number to backend:", error);
      if (error.code === 'failed-precondition' || error.message.includes('ERR_BLOCKED_BY_CLIENT')) {
        toast.error('Please disable your ad blocker or add this site to your allowlist to enable all features.');
      }
    }
  };




  const [phoneNumbersArray, setPhoneNumbersArray] = useState([]);

  
  useEffect(() => {
    if (regularDetails && Object.keys(regularDetails).length > 0) {
      // Extract phone numbers from regularDetails and store in array
      const numbers = Object.values(regularDetails)
        .map(regular => regular.phoneNumber)
        .filter(number => number); // Remove any undefined/null values
      
      setPhoneNumbersArray(numbers);
    }
  }, [regularDetails]);

  useEffect(() => {
  }, [phoneNumbersArray]);
  
  // Modify the whisper function to use all phone numbers
  function whisper() {
    console.log("Checking for updated responses...");
    // Send all phone numbers to backend
    phoneNumbersArray.forEach(phoneNumber => {
      sendPhoneNumberToBackend(phoneNumber);
    });
  }

// Call the whisper function every x minutes (e.g., 5 minutes)
const intervalMinutes = 5;

if(regularDetails.length > 0) {
  setInterval(whisper, intervalMinutes * 60 * 1000);
}else{
  console.log("No regular details");
}

  useEffect(() => {
    fetchUserEvents(userId).then((events) => {
      // console.log("User :", events);
    });

  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      if (!userObject || !userObject.phoneNumber) {
        console.error("User ID not found");
        setLoading(false);
        return;
      }
  
      try {
        const eventsRef = collection(db, 'events');
        
        const q = query(eventsRef, where("createdBy", "==", userObject.phoneNumber));
        const querySnapshot = await getDocs(q);
        
        const fetchedEvents = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.title,
            date: data.date.toDate().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
            time: data.date.toDate().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
            description: data.description,
            location: data.location,
            responseRate: data.responseRate || 0,
            invited: data.invited || 0,
            going: data.goingNumber || 0,
            maybe: data.maybeNumber || 0,
            notGoing: data.notGoingNumber || 0,
          };
        });
  
  
  
        setEvents(fetchedEvents);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setLoading(false);
      }
    };
  
    fetchEvents();
  }, []);

  const[currentEvent, setCurrentEvent] = useState(null);
  useEffect(() => {
    const currentEvent = events.find(event => event.id === id);
    if (currentEvent) {

      setCurrentEvent(currentEvent);
    } else {
    }
  }, [events, id]);



  // Check local storage for isWorking value
  const isWorking = localStorage.getItem("isWorking");
  const textColorClass = isWorking === "true" ? "text-black" : "text-white"; // Set text color based on isWorking

  return (
    <>

<Link 
    to={`/auth/events/${id}`}
    className="event-card-container flex w-full cursor-pointer flex-col items-center justify-center gap-[25px] rounded-md border border-[#E5E7EB] p-[20px] shadow-sm">

      <div className="flex flex-col items-center justify-center ">
      
        <h1 className={`text-center text-[30px] font-medium ${textColorClass}`}>
          {name}
        </h1>
        <Timestamp autoUpdate relative date={event.date} />
        <p className={`text-[14px] font-medium ${textColorClass}`}>
          {event.date}
        </p>
      </div>

      <div className="flex flex-col items-center justify-center gap-[15px]">
        <p className={`text-[16px] ${textColorClass}`}>
          Overall response rate:
          <span className="mx-[5px] font-bold text-orange-500">
            {loading ? "0" : `${responseRate}%`}
          </span>
          <span className="font-medium text-orange-500 ">{`(${totalRegulars} invites)`}</span>
        </p>

        <div className="flex flex-row items-center justify-center gap-[20px]">
          <p className={textColorClass}>🥳 Going: {currentEvent?.going || 0}</p> 
          <p className={textColorClass}>🤔 Maybe: {currentEvent?.maybe || 0}</p> 
          <p className={textColorClass}>😔 Not going: {currentEvent?.notGoing || 0}</p>
        </div>
      </div>
    </Link>
    
    </>

  );
};

export default EventCard;
