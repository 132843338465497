// import Lottie from "react-lottie";
import confetti from "./confetti.json";
import { useNavigate } from "react-router-dom";
import Header from "components/header/HeaderAuthDefault";

export default function Howitworks() {
  const navigate = useNavigate();

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };
  const confettiOptions = {
    loop: true,
    autoplay: true,
    animationData: confetti,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="flex pt-6 h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      {/* <Lottie
        style={{ position: "absolute", height: 400, zIndex: 0 }}
        options={confettiOptions}
      /> */}
      <div className="w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="mt-[0vh] w-full max-w-full flex-col  items-center justify-center  md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <Header />
          <h4 className="justify-left mt-[-38px] flex items-center text-2xl font-bold text-navy-700 dark:text-white">
            Awesome, you're all set.
          </h4>
          <h4 className="justify-left mt-2 flex items-center text-base text-navy-700 dark:text-white">
            You will receive an SMS message with how to get status of your
            bartender.
          </h4>

          <h4 className="justify-left mt-10 flex items-center text-2xl font-bold text-navy-700 dark:text-white">
            How it works
          </h4>
          <h4 className="justify-left mt-2 mb-4 flex items-center text-base text-navy-700 dark:text-white">
            1. Save this page to your bookmarks.
          </h4>
          <h4 className="justify-left mt-2 mb-4 flex items-center text-base text-navy-700 dark:text-white">
            2. You can also track your bartenders status on the following page
            or reply "status" to the Gliddy sms message you just received.
          </h4>
          <h4 className="justify-left flex items-center text-base text-navy-700 dark:text-white">
            3. Get alerts about promotions and events at your favorite
            restaurant.
          </h4>
          <button
            onClick={() => handleRouteClick("/auth/CustomerHome")}
            className="linear z-40  mt-7 w-full rounded-xl bg-brand-500 py-[12px] px-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Ok, got it
          </button>
        </div>
      </div>
    </div>
  );
}
