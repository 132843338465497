import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import EventPage from "views/auth/events/page";
import EventDetails from "views/auth/events/[id]";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="/auth" element={<Navigate to="/auth/sign-in" replace />} />
      <Route path="/auth/events" element={<EventPage />} />
      <Route path="/events/:eventId" element={<EventDetails />} />
      </Routes>
  );
};

export default App;
