import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { removeFollower } from "utils/utils";
import { getLocalStorageValue } from "utils/utils";
import { toast } from "react-toastify";

const FollowingsTable = (props) => {
  const { columnsData, title, rowsData, handleRemoveFollower } = props;
  const tableData = rowsData;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const userPhoneNumber = getLocalStorageValue("userDetails", "phoneNumber")
  const userId = getLocalStorageValue("userDetails", "id")
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  

  async function alertUserAfterRemovFollower(rowPhoneNumber, userPhoneNumber, userId) { 

    const result = await removeFollower(rowPhoneNumber, userPhoneNumber, userId);
    if(result === true) {
      toast.success("User unfollowed successfully");
    } else {
      toast.error("Failed to unfollow user");
    }


  }

  

  const { getTableProps, getTableBodyProps, page, prepareRow, initialState } =
    tableInstance;
  initialState.pageSize = 11;

  return (
    <div extra={"w-full h-full p-4"}>
      <div class="relative flex items-center justify-between">
        <div class="text-l font-bold text-navy-700 dark:text-white">
          {title}
        </div>
      </div>

      <div class="h-full xl:overflow-x-hidden ">
        <table
          {...getTableProps()}
          className="mt-0 h-max w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <tbody {...getTableBodyProps()}>
            {data.map((row, index) => {
              return (
                <tr
                  className="flex justify-between"
                  key={index}
                >
                  <td className="pt-3 pb-3">
                    <div className="flex items-center gap-2">
                      <p className="text-sm font-bold text-navy-700 dark:text-white w-40">
                        {row.name}
                        <p className="text-sm font-normal text-gray-700 dark:text-white">
                          {row.currentWorkPlace ? row.currentWorkPlace : row.workplace}
                        </p>
                      </p>
                    </div>
                  </td>
                  <td className="pt-3 pb-3">
                    <div className="flex items-center justify-between gap-1 ">
                      <button
                        onClick={() => alertUserAfterRemovFollower(row.phoneNumber, userPhoneNumber, userId)}
                        className="linear font-small w-25  ml-3 mt-0 rounded-sm border-orange-500 bg-brand-100 px-3 py-1 text-base font-bold text-orange-500 transition duration-200 hover:bg-brand-100 active:bg-brand-100 dark:bg-brand-300 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      >
                        Unfollow
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FollowingsTable;
