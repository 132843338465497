import { MdQrCodeScanner } from "react-icons/md";
import GenericModal from "views/admin/default/components/GenericModal";
import Invite from "./Invite";
import { useNavigate } from "react-router-dom";
import Qrcode from "./Qrcode";
import CustomerFollowings from "./CustomerFollowings";
import { useEffect, useState, useCallback } from "react";
import { getLocalStorageValue } from "utils/utils";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import Header from "components/header/HeaderAuthDefault";
import { signOut } from "firebase/auth";

import {
  fetchUserByInviteCodeAndUpdateToEachDataabse,
  fetchUserFollowingList,
  removeFollower,
} from "utils/utils";
import { getAuth } from "firebase/auth";

export default function CustomerHome() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const [followings, setFollowings] = useState({});
  const [workingFollowings, setWorkingFollowings] = useState([]);
  const [offWorkFollowings, setOffWorkFollowings] = useState([]);
  const inviteCode = localStorage.getItem("inviteCode");
  const userDetails = getLocalStorageValue("userDetails", "id");
  const userPhoneNumber = getLocalStorageValue("userDetails", "phoneNumber");
  const user = getAuth();
  let currentUser = user.currentUser ? user.currentUser.uid : userDetails;
  let currentUserPhoneNumber = user.currentUser
    ? user.currentUser.phoneNumber
    : userPhoneNumber;
  //Auto follower te user with the ID correalted to the invitaiton code

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (inviteCode) {
      fetchUserByInviteCodeAndUpdateToEachDataabse(
        inviteCode,
        userDetails,
        currentUserPhoneNumber
      );
    }
    localStorage.removeItem("inviteCode");
  }, [inviteCode]);

  useEffect(() => {
    fetchUserFollowingList(currentUserPhoneNumber, userDetails).then((data) => {
      // Ensure data is an object and handle multiple followers
      if (typeof data === "object" && data !== null) {
        setFollowings(data);
      } else {
        console.error(
          "Expected an object with following data, but received:",
          data
        );
      }
    });
  }, [currentUser, loading]);

  useEffect(() => {
    // Map through followings to set working and off work followings
    if (followings && typeof followings === "object") {
      const workingList = Object.values(followings).filter(
        (item) => item.isWorking === true
      );
      const notWorkingList = Object.values(followings).filter(
        (item) => item.isWorking !== true
      );
      setWorkingFollowings(workingList);
      setOffWorkFollowings(notWorkingList);
     
    }

  }, [followings, loading]);

  useEffect(() => {}, [workingFollowings, offWorkFollowings]);

  // const getFollowings = async () => {
  //   setLoading(true);
  //   const endpoint = `/regulars/getAllBartenders/${userId}`;
  //   const response = await httpService("GET", endpoint);
  //   if (response.Error === "HttpException") {
  //     setLoading(false);
  //     toast.error(response.Message);
  //   } else {
  //     setLoading(false);
  //     setFollowings(response);
  //     const workingList = response.filter((item) => item.IsWorking === 1);
  //     const notWorkingList = response.filter((item) => item.IsWorking !== 1);
  //     setWorkingFollowings(workingList);
  //     setOffWorkFollowings(notWorkingList);
  //   }
  // };

  // useEffect(() => {
  //   localStorage.setItem("home", "/auth/customerHome");
  //   getFollowings();
  // }, [userId]);

  const handleLogout = useCallback(
    async (page) => {
      const auth = getAuth();

      try {
        localStorage.clear();
        await signOut(auth);

        document.getElementById("main-body")?.classList.remove("dark");

        // Add a small delay before navigation
        setTimeout(() => {
          console.log("Attempting to navigate to:", page);
          navigate(page);
        }, 100);
      } catch (error) {
        console.error("Error during logout:", error);
      }
    },
    [navigate]
  );

  const handleRemoveFollower = async (bartenderData) => {
    removeFollower(
      bartenderData,
      currentUser.phoneNumber ? currentUser.phoneNumber : userDetails
    );
  };

  // Only existing solution for bookmark button. Inefficient.
  //   const addBookmark = (a) => {
  //     let pageTitle = 'Gliddy';
  //     let pageURL = 'https://www.gliddy.app/';
  //     try {
  //         // Internet Explorer solution
  //         eval("window.external.AddFa-vorite(pageURL, pageTitle)".replace(/-/g, ''));
  //     } catch (e) {
  //         try {
  //             // Mozilla Firefox solution
  //             window.sidebar.addPanel(pageTitle, pageURL, "");
  //         } catch (e) {
  //             // Opera solution
  //             if (typeof('opera') == "object") {
  //                 a.rel = "sidebar";
  //                 a.title = pageTitle;
  //                 a.url = pageURL;
  //                 return true;
  //             } else {
  //                 // The rest browsers (i.e Chrome, Safari)
  //                 alert('Press ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Cmd' : 'Ctrl') + '+D to bookmark this page.');
  //             }
  //         }
  //     }
  //     return false;
  // }

  return (
    <div className="flex h-full justify-center overflow-hidden px-[9px] pt-5 ">
      <Loading loading={loading} loaderColor="#f97316" />
      {/* Customer Home section */}
      <div className="m-auto w-[500px] max-w-[500px] flex-col sm:w-[100%] md:pl-4 lg:pl-0">
        <Header regHome={true} />
        {(!followings || Object.keys(followings).length === 0) && (
          <>
            <div className="mt-10 mb-5 flex items-center justify-center text-4xl font-bold text-brand-500 hover:text-brand-600 dark:text-white">
              <GenericModal
                modalTrigger={
                  <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
                    <MdQrCodeScanner className="h-6 w-6" />
                  </button>
                }
                modalContent={<Qrcode />}
              />
            </div>
            <h4 className="flex items-center justify-center text-xl font-bold text-navy-700 dark:text-white">
              You're not following anyone yet.
            </h4>
            <h4 className="mt-3 flex items-center justify-center text-gray-700 dark:text-white">
              Scan your bartenders QR code to follow
            </h4>
            <h4 className="mb-20 flex items-center justify-center text-gray-700 dark:text-white">
              and receive promotions and event alerts
            </h4>
          </>
        )}

        {followings && Object.keys(followings).length > 0 && (
          <CustomerFollowings
            handleRemoveFollower={handleRemoveFollower}
            offWorkFollowings={offWorkFollowings}
            workingFollowings={workingFollowings}
            setLoading={setLoading}
          />
        )}

        <div className="align-center relative bottom-8">
          <GenericModal
            modalTrigger={
              <button className="linear mb-5 w-full rounded-xl bg-brand-500 py-[12px] px-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                Share Gliddy With Friends
              </button>
            }
            modalContent={
              <Invite
                title="Invite friends to join"
                description="Help grow your bartenders network. Invite friends to follow."
              />
            }
          />

          <button
            onClick={() => handleLogout("/auth/regular-sign-in")}
            style={{ borderWidth: 2, borderColor: "black" }}
            className="linear hover:bg-white-600 active:bg-white-700  text-black 700 w-full rounded-xl bg-white py-[12px] px-2 text-base font-medium transition duration-200"
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
}
