// export default {
//   API_BASE_URL: "https://be.getgliddy.com/api",
//   INVITE_BASE_URL: "https://getgliddy.com",
// };
export default {
  API_BASE_URL: "http://localhost:3001/api",
  INVITE_BASE_URL: "http://localhost:3000",
}


