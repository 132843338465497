// Import the functions you need from the SDKs you need
import { getFirestore, collection, doc, getDocs, getDoc } from 'firebase/firestore';
import { initializeApp, getApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBiP2UN2OZy3gjUAYRiRrJVKIVXQgOQq14",
  authDomain: "gliddy-backend.firebaseapp.com",
  projectId: "gliddy-backend",
  storageBucket: "gliddy-backend.appspot.com",
  messagingSenderId: "122399672307",
  appId: "1:122399672307:web:87497f17ec911ad4cdf486",
  measurementId: "G-MH68LCT2H3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Auth with explicit configuration
const auth = getAuth();
auth.settings = {
  appVerificationDisabledForTesting: false
};

export { app, auth };
export const db = getFirestore(app);