import React from "react";
 

// Auth Imports
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/SignUp";

// Icon Imports
import {
  MdLocalPhone,
  MdVerifiedUser,
  MdLock,
} from "react-icons/md";
import Verify from "views/auth/Verify";
import Workplace from "views/auth/Workplace";
import Landing from "views/auth/Landing";
import Queensbar from "views/auth/Queensbar";
import Howitworks from "views/auth/Howitworks";
import CustomerHome from "views/auth/CustomerHome";
import CustomerFollowings from "views/auth/CustomerFollowings";
import Settings from "views/auth/Settings";
import Editprofile from "views/auth/Editprofile";
import Feedback from "views/auth/Feedback";
import Events from "views/auth/CreateEvent";
import EventPage from "views/auth/events/page";
import EventDetails from "views/auth/events/[id]";
import ManageEvents from "views/auth/manage-events/page";
import EditEventId from "views/auth/events/createEvent/[id]";

const routes = [
  {
    name: "Sign In",
    layout: "/auth",
    path: "default",
    icon: <MdLock className="h-6 w-6" />,
    component: <Landing />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Phone Number",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLocalPhone className="h-6 w-6" />,
    component: <SignUp />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "regular-sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Phone Number",
    layout: "/auth",
    path: "regular-sign-up",
    icon: <MdLocalPhone className="h-6 w-6" />,
    component: <SignUp />,
  },
  // {
  //   name: "Verification",
  //   layout: "/auth",
  //   path: "verify",
  //   icon: <MdVerifiedUser className="h-6 w-6" />,
  //   component: <Verify />,
  // },
  {
    name: "Gliddy",
    layout: "/auth",
    path: "landing",
    icon: <MdVerifiedUser className="h-6 w-6" />,
    component: <Landing />,
  },
  {
    name: "WorkPlace",
    layout: "/auth",
    path: "workplace",
    icon: <MdLock className="h-6 w-6" />,
    component: <Workplace />,
  },
  {
    name: "Queensbar",
    layout: "/auth",
    path: "workplace-home",
    icon: <MdLock className="h-6 w-6" />,
    component: <EventPage />,
  },
  {
    name: "Settings",
    layout: "/auth",
    path: "settings",
    icon: <MdLock className="h-6 w-6" />,
    component: <Settings />,
  },
  {
    name: "Edit Profile",
    layout: "/auth",
    path: "edit-profile",
    icon: <MdLock className="h-6 w-6" />,
    component: <Editprofile />,
  },
  {
    name: "Feedback",
    layout: "/auth",
    path: "send-feedback",
    icon: <MdLock className="h-6 w-6" />,
    component: <Feedback />,
  },

  {
    name: "How It Works",
    layout: "/auth",
    path: "welcome",
    icon: <MdLock className="h-6 w-6" />,
    component: <Howitworks />,
  },
  {
    name: "CustomerHome",
    layout: "/auth",
    path: "CustomerHome",
    icon: <MdLock className="h-6 w-6" />,
    component: <CustomerHome />,
  },
  {
    name: "CustomerFollowings",
    layout: "/auth",
    path: "CustomerFollowings",
    icon: <MdLock className="h-6 w-6" />,
    component: <CustomerFollowings />,
  },
  {
    name: "CreateEvent",
    layout: "/auth",
    path: "create-event",
    icon: <MdLock className="h-6 w-6" />,
    component: <Events />,
  },
  // {
  //   name: "EventPage",
  //   layout: "/auth",
  //   path: "events",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <EventPage />,
  // },
  {
    name: "EventDetails",
    layout: "/auth",
    path: "events/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <EventDetails />,
  },
  {
    name: "EditEventId",
    layout: "/auth",
    path: "events/createEvent/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <EditEventId />,
  },
  {
    name: "ManageEvents",
    layout: "/auth",
    path: "manage-events",
    icon: <MdLock className="h-6 w-6" />,
    component: <ManageEvents />,
  },
];
export default routes;
