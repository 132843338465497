import { default as createClient } from '@azure-rest/ai-vision-image-analysis';
import { AzureKeyCredential } from '@azure/core-auth';

let credential;
let client;

const AZURE_VISION_ENDPOINT = "https://visiongliddy.cognitiveservices.azure.com/"
const AZURE_VISION = "Fh5fpwAALobMhnPssSYzLI0YUWqDm9GYaOuBpDPdptRHhUv5JckkJQQJ99AKACYeBjFXJ3w3AAAFACOG4k9T"

if (AZURE_VISION && AZURE_VISION_ENDPOINT) {
    credential = new AzureKeyCredential(AZURE_VISION);
    client = createClient(AZURE_VISION_ENDPOINT, credential);
}

export async function analyzeImage(imageDataUrl) {
    if (!AZURE_VISION || !AZURE_VISION_ENDPOINT) {
        throw new Error("Azure credentials not configured");
    }

    // console.log('Image Data Type:', typeof imageDataUrl);
    // console.log('Image Data Length:', imageDataUrl?.length);
    // console.log('Image Data Start:', imageDataUrl?.substring(0, 50));
    // console.log('Contains "data:"?', imageDataUrl?.includes('data:'));
    // console.log('Contains "base64"?', imageDataUrl?.includes('base64'));

    // Simple validation
    if (!imageDataUrl?.includes('base64,')) {
        console.error('Invalid format received:', {
            type: typeof imageDataUrl,
            preview: imageDataUrl?.substring(0, 100),
            isNull: imageDataUrl === null,
            isUndefined: imageDataUrl === undefined
        });
        throw new Error('Image must be in base64 data URL format');
    }

    // Convert base64 to binary (browser-compatible version)
    const base64Data = imageDataUrl.split(',')[1];
    const binaryData = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));

    const features = ['Caption', 'Read'];
          
    const result = await client.path('/imageanalysis:analyze').post({
        body: binaryData,
        queryParameters: {
            features: features
        },
        contentType: 'application/octet-stream'
    });

    console.log(result.body);
    return result.body;
}
