const ArrowLeft = () => {
  const isWorking = localStorage.getItem('isWorking') === 'true';
  
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="arrow-left">
        <path
          id="Vector"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15 8.00002C15 7.86741 14.9473 7.74024 14.8536 7.64647C14.7598 7.5527 14.6326 7.50002 14.5 7.50002H2.70701L5.85401 4.35402C5.9005 4.30753 5.93737 4.25234 5.96253 4.1916C5.98769 4.13087 6.00064 4.06577 6.00064 4.00002C6.00064 3.93428 5.98769 3.86918 5.96253 3.80844C5.93737 3.7477 5.9005 3.69251 5.85401 3.64602C5.80752 3.59953 5.75233 3.56266 5.69159 3.5375C5.63085 3.51234 5.56575 3.49939 5.50001 3.49939C5.43426 3.49939 5.36916 3.51234 5.30842 3.5375C5.24769 3.56266 5.1925 3.59953 5.14601 3.64602L1.14601 7.64602C1.09945 7.69247 1.0625 7.74764 1.0373 7.80839C1.01209 7.86913 0.999115 7.93425 0.999115 8.00002C0.999115 8.06579 1.01209 8.13091 1.0373 8.19165C1.0625 8.2524 1.09945 8.30758 1.14601 8.35402L5.14601 12.354C5.1925 12.4005 5.24769 12.4374 5.30842 12.4625C5.36916 12.4877 5.43426 12.5007 5.50001 12.5007C5.56575 12.5007 5.63085 12.4877 5.69159 12.4625C5.75233 12.4374 5.80752 12.4005 5.85401 12.354C5.9005 12.3075 5.93737 12.2523 5.96253 12.1916C5.98769 12.1309 6.00064 12.0658 6.00064 12C6.00064 11.9343 5.98769 11.8692 5.96253 11.8084C5.93737 11.7477 5.9005 11.6925 5.85401 11.646L2.70701 8.50002H14.5C14.6326 8.50002 14.7598 8.44734 14.8536 8.35357C14.9473 8.25981 15 8.13263 15 8.00002Z"
          fill={isWorking ? "#1F2937" : "#FFFFFF"}
        />
      </g>
    </svg>
  );
};

export default ArrowLeft; 