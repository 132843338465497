import React from "react";
import GenericModal from "views/admin/default/components/GenericModal";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import OfflineMode from "components/offlineMode/OfflineMode";
import MyWorkplaces from "views/admin/default/components/MyWorkplaces";
import "../../../components/offlineMode/OfflineMode.css";

import {
  getLocalStorageValue,
  getLocalStorageObject,
  fetchUserRegulars,
  fetchAllRegularsDetails,
  countRegulars,
  fetchRegularDetails,
  toggleUserWorkingStatus,
  fetchLoggedInUserDetails,
  fetchSmsTemplateMessage,
} from "utils/utils";


import { RegularcolumnsData } from "views/admin/tables/variables/columnsData";
import RegularsTable from "views/admin/tables/components/RegularsTable";



// Compoonents that we will be using
import HeaderEvent from "components/header/HeaderEvent";
import Image from "assets/img/icons/image-placeholder.svg";
import { Link } from "react-router-dom";
import EventCard from "./components/EventCard";
import { db } from "../../../firebase/firebase";
import { collection, query, getDocs, where } from "firebase/firestore";

import { getAuth } from "firebase/auth";

const EventPage = ({
  title,
  description,
  time,
  onContinue,
  onBack,
  modalContent,
  latitude,
  longitude,
}) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const userObject = getLocalStorageObject("userDetails");
  const [isChecked, setIsChecked] = useState(false);

  const [regulars, setRegulars] = useState(0);
  const [regularDetails, setRegularDetails] = useState([]);
  const [totalRegulars, setTotalRegulars] = React.useState(0);
  const [userName, setUserName] = useState("");
  const [workingStatus, setWorkingStatus] = useState("Working");
  const [workplaceName, setWorkplaceName] = useState("");
  const [fullRegularDetails, setFullRegularDetails] = useState([]);
  const [isWorking, setIsWorking] = useState(() => {
    const stored = localStorage.getItem("isWorking");
    return stored === "true";  // Convert string to boolean
  });  
  const [workplaces, setWorkplaces] = useState([]);

  const [templateMessage, setTemplateMessage] = useState("");
  const [regularPhoneNumbers, setRegularPhoneNumbers] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [smsStatus, setSmsStatus] = useState(null);

  const userId = getLocalStorageValue("userDetails", "phoneNumber");
  const currentWorkplace = getLocalStorageValue(
    "userDetails",
    "currentWorkplace"
  );

//Funtion to extrat only the bussiness naem 

function extractBusinessName(address) {
  const parts  = address.split(',')
  return parts[0]
}

  useEffect(() => {
  }, [isWorking]) 
  useEffect(() => {
    if (userObject) {
      setUserName(userObject.name);
    }
  }, [userObject, userName]);

  const [buttonPressed, setButtonPressed] = useState(false);
  const handleToggle = () => {
    setIsChecked(!isChecked);
    setButtonPressed(true);

    // Check conditions and send SMS here instead of in useEffect
    if (isChecked && !isWorking) {
      // if turning ON (isChecked is current state before toggle)
      // sendSMS();
    }
  };
  const handleButtonClick = (e) => {
    handleToggle();
    if (e === false) {
      updateWorkStatus(true);
      if (workplaces.length === 1) {
        if (workplaceName) {
          updateWorkStatus(true);
          // messageRegulars();
        }
      }
    } else {
      updateWorkStatus(false);
    }
  };

  const updateWorkStatus = async (workStatus) => {
    try {
      // setLoading(true);
      const newStatus = await toggleUserWorkingStatus(userId);
      if (newStatus !== null) {
        setLoading(true);
        setIsWorking(newStatus);
        localStorage.setItem("isWorking", newStatus);
      }
    } catch (error) {
      console.error("Error updating work status:", error);
      toast.error("Failed to update work status");
    } finally {
      setLoading(false);
    }
  };

  const fetchAndLogUserData = async () => {
    try {
      // setLoading(true);
      const userRegulars = await fetchUserRegulars(userId);
      const userRegularDetails = await fetchRegularDetails(userId);

      setRegulars(userRegulars);
      setTotalRegulars(userRegulars.length);
      setFullRegularDetails(userRegularDetails);
      setIsWorking(userRegularDetails.isWorking);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data");
    } finally {
      // setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchAndLogUserData();
    localStorage.setItem("home", "/auth/workplace");
  }, []);



  useEffect(() => {
    if (regulars.length > 0) {
      const fetchRegularsDetails = async () => {
        const regularDetails = await fetchAllRegularsDetails(regulars);
        setRegularDetails(regularDetails.mappedUsers);
      };
      fetchRegularsDetails();


      const count = countRegulars(regulars);
      localStorage.setItem("totalRegulars", count);
      setTotalRegulars(count);
    }
  }, [regulars]);

  useEffect(() => {}, [regularDetails]);

  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const sliderRef = useRef(null);
  const cardRefs = useRef([]);

  const nextEvent = () => {
    setCurrentEventIndex((prevIndex) =>
      prevIndex === events.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevEvent = () => {
    setCurrentEventIndex((prevIndex) =>
      prevIndex === 0 ? events.length - 1 : prevIndex - 1
    );
  };

  //Now we fetch events from the database

  useEffect(() => {
    const fetchEvents = async () => {
      if (!userObject || !userObject.id) {
        console.error("User ID not found");
        // setLoading(false);
        return;
      }

      try {
        const eventsRef = collection(db, "events");
        const q = query(
          eventsRef,
          where("createdBy", "==", userObject.phoneNumber)
        );
        const querySnapshot = await getDocs(q);

        const fetchedEvents = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.title,
            date: data.date.toDate().toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }),
            time: data.date.toDate().toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            }),
            description: data.description,
            location: data.location,
            responseNumber: data.responseNumber || 0,
            invited: data.invitesSent || 0,
            going: data.goingNumber || 0,
            maybe: data.maybeNumber || 0,
            notGoing: data.notGoingNumber || 0,
          };
        });

        // console.log("This is the fetched events", );

        setEvents(fetchedEvents);
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  // useEffect(() => {

  const logUserDetails = async () => {
    setLoading(true);
    const userDetails = await fetchLoggedInUserDetails(userId);

    if (userDetails.workplaces.length > 0) {
      setWorkplaceName(userDetails.currentWorkplace);
      setLoading(false);
    } else {
      setLoading(false);
      return;
    }
  };

  // Call logUserDetails function where appropriate, for example, in useEffect or a button click
  useEffect(() => {
    logUserDetails();
  }, []);

  const setWorkplaceFunction = (data) => {
    setWorkplaceName(data); // Assuming you have a state variable for workplaceName
  };

  //Sending If Online

  useEffect(() => {
    if (regulars) {
      const count = countRegulars(regulars);
      setTotalRegulars(count);

      const fetchRegularsDetails = async () => {
        const regularNames = await fetchAllRegularsDetails(regulars);

        setRegularDetails(regularNames.mappedUsers);

        // Convert object to array and extract phone numbers
        const phoneNumbers = Object.values(regularNames.mappedUsers)
          .map((regular) => regular.phoneNumber)
          .filter(Boolean);

        setRegularPhoneNumbers(phoneNumbers);
      };
      fetchRegularsDetails();
    }
  }, [regulars]);

  // Call the function where appropriate, e.g., in useEffect or a button click
  useEffect(() => {
    // fetchSmsTemplates(); // Fetch SMS templates when the component mounts
    const fetchTemplate = async () => {
      const templateMessage = await fetchSmsTemplateMessage();
      setTemplateMessage(templateMessage);
    };
    fetchTemplate();
  }, []);

  const sendSMS = async (eventId) => {
    setIsSending(true);
    setSmsStatus(null);

    if (!userId) {
      setSmsStatus("Error: Sender ID is missing");
      setIsSending(false);
      return;
    }

    const message = `Hey ${userName} is now online at ${workplaceName}`;

    try {
      const response = await fetch(
        "https://lr81vgidw9.execute-api.us-east-1.amazonaws.com/prod",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            senderId: userId,
            phoneNumbers: regularPhoneNumbers,
            message: message,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setSmsStatus("SMS sent successfully to all recipients");
        toast.success("SMS sent successfully to all recipients");
      } else {
        setSmsStatus(
          `Failed to send SMS: ${result.message || "Unknown error"}`
        );
      }
    } catch (error) {
      setSmsStatus(`Error: ${error.message}`);
    } finally {
      setIsSending(false);
    }
  };




  return (
    <div
      className={`relative   float-right h-full min-h-screen w-full ${
        isWorking ? "bg-white" : "  bg-navy-900 !text-white"
      }`}
    >
      <main className={`mx-auto min-h-screen`}>
        <div className="relative flex">
          <div className="mx-auto flex min-h-full  w-full flex-col justify-start pt-0  lg:h-screen  lg:pt-0 xl:h-[100vh]  xl:px-0 ">
            <div
              className={
                "mb-auto flex max-h-[100vh] flex-col overflow-y-auto overflow-x-hidden   xl:max-w-full"
              }
            >
              <div className="m-auto w-[500px] max-w-[500px] overflow-x-hidden px-[10px] sm:w-[100%]">
                {/* <Loading loading={loading} loaderColor="#f97316" /> */}

                <HeaderEvent
                  tagline="Regulars"
                  regHome={false}
                  regCreate={false}
                  totalRegulars={totalRegulars}
                />

                <div className="flex w-full flex-col items-center justify-center gap-[2px]">
                  <div className="profile-container">
                    <img src={Image} alt="profile" />
                  </div>

                  <p>{userName}</p>
                </div>

                <div className=" mt-[19px] flex w-full flex-col items-center justify-center gap-[10px] text-center">
                  <p className="font-bold uppercase text-[#64748B]">
                    {workingStatus} @
                  </p>
                  <h4 className=" mt-3 mb-3 flex  items-center justify-center text-4xl text-brand-500 dark:text-white">
                    {workplaceName && workplaceName.length > 0
                      ? `${extractBusinessName(workplaceName)}${!isWorking ? " (offline)" : ""}`
                      : "No workplace set"}
                  </h4>
                  <div className="mt-2 ml-1 flex cursor-pointer justify-center">
                    <GenericModal
                      modalTrigger={
                        <OfflineMode
                          onChange={(e) => handleButtonClick(e)}
                          isDisabled={workplaces.length === 0}
                          isWorking={
                            localStorage.getItem("isWorking") === "true" ||
                            isWorking
                          }
                        ></OfflineMode>
                      }
                      modalSuppress={isWorking || workplaces.length <= 1}
                      modalContent={
                        <MyWorkplaces
                          buttonText="Confirm Workplace"
                          headerTitle="Select your workplace"
                          setWorkplaceFunction={setWorkplaceFunction} // Ensure this is passed correctly
                        />
                      }
                    />
                  </div>
                </div>

                <div className="mt-[10px] flex flex-col gap-[15px]">
                  <div className="relative overflow-hidden">
                    <div
                      ref={sliderRef}
                      className="flex"
                      style={{
                        transform: `translateX(-${currentEventIndex * 50}%)`,
                        width: `${events.length * 100}%`,
                        transition: "transform 150ms ease-in-out",
                      }}
                    >
                      {events
                        .filter(event => new Date(event.date) > new Date()) // Filter out past events
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .map((event, index) => (
                          <div
                            key={event.id}
                            ref={(el) => (cardRefs.current[index] = el)}
                            className="w-full flex-shrink-0"
                            style={{ width: `${100 / events.length}%` }}
                          >
                            <div className="mx-auto max-w-[500px]">
                              <EventCard
                                event={event}
                                regularDetails={regularDetails}
                                loading={loading}
                                setLoading={setLoading}
                              />
                            </div>
                          </div>
                        ))}
                    </div>

                    {/* Navigation buttons */}
                    {events.length > 1 && (
                      <>
                        <button
                          onClick={prevEvent}
                          className="absolute left-0 top-1/2 -translate-y-1/2 p-2 text-gray-600 hover:text-gray-900"
                          aria-label="Previous event"
                        >
                          ←
                        </button>
                        <button
                          onClick={nextEvent}
                          className="absolute right-0 top-1/2 -translate-y-1/2 p-2 text-gray-600 hover:text-gray-900"
                          aria-label="Next event"
                        >
                          →
                        </button>
                      </>
                    )}
                  </div>

                  {/* Dots indicator */}
                  {events.length > 1 && (
                    <div className="mt-4 flex justify-center gap-2">
                      {events.map((_, index) => (
                        <div
                          key={index}
                          className={`h-2 w-2 rounded-full transition-all duration-300 ${
                            currentEventIndex === index
                              ? "w-4 bg-brand-500"
                              : "bg-gray-300"
                          }`}
                          onClick={() => setCurrentEventIndex(index)}
                          style={{ cursor: "pointer" }}
                        />
                      ))}
                    </div>
                  )}

                  {/* Create into a modal the visitors card  */}

                  <div className="mt-[25px] flex flex-col gap-[8px]">
                    <Link
                      to={"/auth/manage-events"}
                      className={`linear bg-transparent flex h-[62px] w-full items-center justify-center rounded-xl border-[2px] transition-all duration-300 ${
                        isWorking ? "border-[#000]" : "border-white"
                      } px-2 text-base font-medium transition-colors duration-300 ${
                        isWorking ? "text-black" : "text-white"
                      }`}
                      style={{
                        color: isWorking ? "#000" : "#fff"
                      }}
                    >
                      Manage Events
                    </Link>
                    <GenericModal
                      modalTrigger={
                        <button
                          className={`linear bg-transparent flex h-[62px] w-full items-center justify-center rounded-xl border-[2px] transition-all duration-300 ${
                            isWorking ? "border-[#000]" : "border-white"
                          } px-2 text-base font-medium transition-colors duration-300 ${
                            isWorking ? "text-black" : "text-white"
                          }`}
                        >
                          Regulars
                        </button>
                      }
                      modalContent={
                        <RegularsTable
                          columnsData={RegularcolumnsData}
                          regularsData={regularDetails}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default EventPage;
