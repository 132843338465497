import { doc, getDoc, updateDoc, collection, getDocs, arrayUnion  } from "firebase/firestore";
import {db} from '../firebase/firebase'


export const getLocalStorageValue = (key, property) => {
  var localStorageItem = localStorage.getItem(key);





  // If the item does not exist in localStorage, return null
  if (!localStorageItem) {
    return null;
  }


  // Parse the localStorage item from a JSON string to a JavaScript object
  var localStorageObject = JSON.parse(localStorageItem);

  // Return the value associated with the property
  return localStorageObject[property];
};




export const getLocalStorageObject = (key) => {
  var localStorageItem = localStorage.getItem(key);



  // If the item does not exist in localStorage, return null
  if (!localStorageItem) {
    return null;
  }

  // Parse the localStorage item from a JSON string to a JavaScript object
  var localStorageObject = JSON.parse(localStorageItem);

  return localStorageObject
}


export const formatDate = (dateString) => {
  const date = new Date(dateString);

  return date.toISOString().split('T')[0]; // This will give "YYYY-MM-DD"
};


export const formatDateLong = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

//Dealing with Firestore

export const fetchAllUserIds = async () => {
  try {
    const usersCollectionRef = collection(db, "users");
    const usersSnapshot = await getDocs(usersCollectionRef);
    
    // Map through all documents and extract just the IDs
    const userIds = usersSnapshot.docs.map(doc => doc.id);
    
    return userIds;
  } catch (error) {
    console.error("Error fetching user IDs:", error);
    return [];
  }
};

export const fetchUserRegulars = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userDocRef);



    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      return userData.regulars || [];
    } else {
      console.log("No data available for this user");
      return [];
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

//Sending SMS top subscring USERS

export const fetchAllUserPhoneNumbers = async () => {
  try {
    const usersCollectionRef = collection(db, "users");
    const usersSnapshot = await getDocs(usersCollectionRef);
    
    // Map through all documents and create objects with id and phoneNumber
    const userPhoneNumbers = usersSnapshot.docs
      .map(doc => ({
        id: doc.data().id,
        phoneNumber: doc.data().phoneNumber
      }))
      .filter(user => user.phoneNumber); // Filter out any entries without phone numbers
    
    return userPhoneNumbers;
  } catch (error) {
    console.error("Error fetching user phone numbers:", error);
    return [];
  }
};



export const fetchRegularDetails = async (regularId) => {

if(regularId === "NaN" || regularId === undefined || regularId === null){
  return null;
}

  try {
    const regularDocRef = doc(db, "users", regularId);
    const regularSnapshot = await getDoc(regularDocRef);




    if (regularSnapshot.exists()) {
      const regularData = regularSnapshot.data();
      return regularData;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching regular data:", error);
    return null;
  }
};

export const fetchAllRegularsDetails = async (regulars) => {
  


  const regularNames = await Promise.all(
    regulars.map(regularId => fetchRegularDetails(regularId))
  );

  // New code to map users to regulars
  const mappedUsers = await mapUsersToRegulars(regulars); // {{ edit_1 }}
return { regularNames: regularNames.filter(Boolean), mappedUsers }; // {{ edit_2 }}
};

export const countRegulars = (regulars) => {
  return regulars.length;
};



export const mapUsersToRegulars = async (regulars) => {
  try {
    // Step 1: Fetch all users from Firestore
    const usersCollectionRef = collection(db, "users");
    const usersSnapshot = await getDocs(usersCollectionRef);
    
    // Step 2: Create a mapping of user IDs to user data
    const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // Step 3: Filter users to find those that match the regulars IDs
    const matchedUsers = usersData.filter(user => regulars.includes(user.id));

    // Step 4: Create a mapped document
    const mappedDocument = matchedUsers.reduce((acc, user) => {
      acc[user.id] = user; // Map user ID to user data
      return acc;
    }, {});

    return mappedDocument; // Return the mapped document
  } catch (error) {
    console.error("Error mapping users to regulars:", error);
    return null; // Return null in case of error
  }
};


export const logWorkplaces = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userDocRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      const workplaces = userData.workplaces || {};
  

      // Check if workplaces is an object
      if (typeof workplaces === 'object' && !Array.isArray(workplaces)) {
        const workplaceName = workplaces.selectedWorkplaces?.[0] || '';
        const barData = workplaces.barData || '';
        const workplaceReturn = `${workplaceName} - ${barData}`
        console.log(`${workplaceName} - ${barData}`);
        return workplaceReturn;
      } else {
        console.log("No workplaces found or invalid format");
      }

      return workplaces;
    } else {
      console.log(`No data available for user ${userId}`);
      return {};
    }
  } catch (error) {
    console.error("Error fetching user workplaces:", error);
    throw error;
  }
};

export const fetchLoggedInUserDetails = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userDocRef);

    if (userSnapshot.exists()) {
      return userSnapshot.data();
    } else {
      console.log("No data available for this user");
      return null;
    }
  } catch (error) {
    console.error("Error fetching logged-in user details:", error);
    return null;
  }
};



export const toggleUserWorkingStatus = async (userId, isWorking, newStatus) => {



  try {
    const userDocRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userDocRef);

    

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      
      const currentStatus = userData.isWorking ;
      if(currentStatus === newStatus){
        return ;
      }else if (currentStatus !== newStatus) {
        const newStatus = !currentStatus;
        await updateDoc(userDocRef, { isWorking: newStatus });
        return newStatus;
      }
    } else {
      console.log("No user document found");
      return null;
    }
  } catch (error) {
    console.error("Error toggling user working status:", error);
    throw error;
  }
};



export const combineWorkplaceInfo = (workplaceObj) => {
  if (!workplaceObj || typeof workplaceObj !== 'object') {
    return '';
  }

  const { workplace, address } = workplaceObj;
  
  const workplaceName = workplace || '';
  const workplaceAddress = Array.isArray(address) ? address[0] : address || '';

  return `${workplaceName}${workplaceAddress ? ` ${workplaceAddress}` : ''}`.trim();
};

//We are going to fetch the user db.
//Suppoert function 
export const addUserToRegulars = async (userId, regularId, currentUserId) => { // {{ edit_1 }} - New function to add userId to regulars
  try {
    const userDocRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userDocRef);

    
    const currentUserDocRef = doc(db, "users", currentUserId);
    const currentUserSnapshot = await getDoc(currentUserDocRef);

    if(currentUserSnapshot.exists()) {
      const currentUserData = currentUserSnapshot.data();
      const updatedFavoriteBarIds = Array.isArray(currentUserData.favoriteBarIds) ? currentUserData.favoriteBarIds : []; // Ensure regulars is an array

      if (!updatedFavoriteBarIds.includes(userId)) { // Check if the regularId is not already in the array
        updatedFavoriteBarIds.push(userId); // Add the new regularId
        await updateDoc(currentUserDocRef, { favoriteBarIds: updatedFavoriteBarIds }); // Update Firestore
  
      }
    }else{
      console.log("No user document found for currentUserId: ${currentUserId}");
    }



    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      const updatedRegulars = Array.isArray(userData.regulars) ? userData.regulars : []; // Ensure regulars is an array



      if (!updatedRegulars.includes(regularId)) { // Check if the regularId is not already in the array
        updatedRegulars.push(regularId); // Add the new regularId
        await updateDoc(userDocRef, { regulars: updatedRegulars }); // Update Firestore
        // console.log(`Added ${regularId} to ${userId}'s regulars.`);
      } else {
        // console.log(`${regularId} is already in ${userId}'s regulars.`);
      }
    } else {
      console.log(`No user document found for userId: ${userId}`);
    }
  } catch (error) {
    console.error("Error adding user to regulars:", error);
  } 
};

export const fetchUserByInviteCodeAndUpdateToEachDataabse = async (inviteCode, userId, currentUserId) => {
  try {
    const usersCollectionRef = collection(db, "users"); // Reference to the users collection
    const usersSnapshot = await getDocs(usersCollectionRef); // Fetch all documents in the users collection

    const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // {{ edit_2 }} - Include document ID and data


    const user = usersData.find(user => user.id === inviteCode);

    //Added the user to the Staffs following list 
    addUserToRegulars(user.phoneNumber, userId, currentUserId)

    //add the staff to the current user following list 



  } catch (error) {
    console.error("Error fetching user by invite code:", error);
    return null;
  }

}

export const removeFollower = async (userId, currentUserId, currentUserUID) => {
  try {
    const userDocRef = doc(db, "users", currentUserId);
    const userSnapshot = await getDoc(userDocRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      console.log("userId", userId);
      console.log("currentUserId", currentUserId);
      const updatedRegulars = Array.isArray(userData.regulars) ? userData.regulars : [];
      const updatedFavoriteBarIds = Array.isArray(userData.favoriteBarIds) ? userData.favoriteBarIds : [];
      const filteredRegulars = updatedRegulars.filter(regular => regular !== userId);
      const filteredFavoriteBarIds = updatedFavoriteBarIds.filter(barId => barId !== userId);

      await updateDoc(userDocRef, { regulars: filteredRegulars, favoriteBarIds: filteredFavoriteBarIds });


      // New code to remove currentUserId from userId's regulars
      const targetUserDocRef = doc(db, "users", userId); // Reference to the userId document
      const targetUserSnapshot = await getDoc(targetUserDocRef);

      
      if (targetUserSnapshot.exists()) {
        const targetUserData = targetUserSnapshot.data();
        const targetUserRegulars = Array.isArray(targetUserData.regulars) ? targetUserData.regulars : [];

        const updatedTargetUserRegulars = targetUserRegulars.filter(regular => regular !== currentUserUID); // Remove currentUserId

        await updateDoc(targetUserDocRef, { regulars: updatedTargetUserRegulars }); // Update Firestore
      }

      return true;
    }
  } catch (error) {
    console.error("Error removing follower:", error);
    return false;
  }
};

export const fetchUserFollowingList = async (userId, currentUserId) => {
  try {

    const usersCollectionRef = collection(db, "users"); // Reference to the users collection
    const usersSnapshot = await getDocs(usersCollectionRef); // Fetch all documents in the users collection
    const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // {{ edit_1 }} - Include document ID and data


    const user = usersData.find(user => user.id === currentUserId);



  
    // New code to handle followingIds
    if (user && user.favoriteBarIds) {
      const favoriteBarIds = user.favoriteBarIds;
      if (favoriteBarIds.length > 1) {

        // Fetch user details for each favoriteBarId
        const followingUsers = await Promise.all(favoriteBarIds.map(async (barId) => {
          const userDocRef = doc(db, "users", barId);
          const userSnapshot = await getDoc(userDocRef);

          const follwingUsers = await fetchRegularDetails(barId);
          
          // Collect names, workplaces, and isWorking into an object
          return {
            name: follwingUsers?.name || 'Unknown', // Default to 'Unknown' if name is null
            currentWorkPlace: follwingUsers?.currentWorkplace || 'No workplace', // Default to 'No workplace' if null
            isWorking: follwingUsers?.isWorking || false,
            phoneNumber: follwingUsers?.phoneNumber || 'No phone number'
          };
        }));

        // New code to log and return structured details
        const followingUsersDetails = followingUsers.map(user => ({
          name: user.name || 'Unknown', // Default to 'Unknown' if name is null
          currentWorkPlace: user.currentWorkplace || 'No workplace', // Default to 'No workplace' if null
          isWorking: user.isWorking || false,
          phoneNumber: user.phoneNumber

        }));





        return followingUsersDetails;
      } else if (favoriteBarIds.length === 1) {
        const followingUser = await fetchRegularDetails(favoriteBarIds[0]);
        return {
          [0]: {
            workplace: followingUser.currentWorkplace,
            isWorking: followingUser.isWorking,
            name: followingUser.name,
            phoneNumber: followingUser.phoneNumber

          }
        };
      } else {
        console.log("No following IDs found.");
      }
    } else {
      console.log("User not found or no favoriteBarIds IDs.");
    }
  } catch (error) {
    console.error("Error fetching user following list:", error);
    return [];
  }
}
export const fetchUserEvents = async (userId) => {
  try {
    const eventsCollectionRef = collection(db, "events"); // Reference to the events collection
    const eventsSnapshot = await getDocs(eventsCollectionRef); // Fetch all documents in the events collection

    // Log the "createdBy" field for each event that matches the userId
    const userEvents = eventsSnapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() })) // {{ edit_1 }} - Include document ID
      .filter(eventData => eventData.createdBy === userId); 

    // Return the latest event created by the user
    const latestEvent = userEvents.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]; // {{ edit_2 }}
    return latestEvent || null; // Return null if no events found

  } catch (error) {
    console.error("Error fetching user events:", error);
    return [];
  }
}

// Function to update the response number of the latest event
export const updateResponseNumber = async (eventId, currentResponseNumber) => {
  try {

    const updatedResponseNumber = currentResponseNumber + 1; // Increment the response number
    await updateDoc(doc(db, "events", eventId), { responseNumber: updatedResponseNumber }); // Update Firestore
    console.log("Updated Response Number", updatedResponseNumber);
  } catch (error) {
    console.error("Error updating response number:", error);
  }
};
export const updateGoingNumber = async (eventId, currentResponseNumber) => {
  try {

    const updatedResponseNumber = currentResponseNumber + 1; // Increment the response number
    await updateDoc(doc(db, "events", eventId), { goingNumber: updatedResponseNumber }); // Update Firestore
    console.log("Updated Going Number", updatedResponseNumber);
  } catch (error) {
    console.error("Error updating response number:", error);
  }
};


export const updateNotGoingNumber = async (eventId, currentResponseNumber) => {
  try {

    const updatedResponseNumber = currentResponseNumber + 1; // Increment the response number
    await updateDoc(doc(db, "events", eventId), { notGoingNumber: updatedResponseNumber }); // Update Firestore
    console.log("Updated Response Number", updatedResponseNumber);
  } catch (error) {
    console.error("Error updating response number:", error);
  }
};

export const updateMaybeNumber = async (eventId, currentResponseNumber) => {
  try {

    const updatedResponseNumber = currentResponseNumber + 1; // Increment the response number
    await updateDoc(doc(db, "events", eventId), { maybeNumber: updatedResponseNumber }); // Update Firestore
  } catch (error) {
    console.error("Error updating response number:", error);
  }
};

export const updatePhoneNumbers = async (eventId, phoneNumber) => { // {{ edit_1 }} - Updated to avoid using firebase.firestore.FieldValue.arrayUnion


  try {
    const eventDocRef = doc(db, "events", eventId);
    const eventSnapshot = await getDoc(eventDocRef); // Fetch the current event document
    if (eventSnapshot.exists()) {
      const eventData = eventSnapshot.data();
      const updatedPhoneNumbers = Array.isArray(eventData.phoneNumbers) ? eventData.phoneNumbers : []; // Ensure phoneNumbers is an array
      if (!updatedPhoneNumbers.includes(phoneNumber)) { // Check if the phone number is not already in the array
        updatedPhoneNumbers.push(phoneNumber); // Add the new phone number
      }
      await updateDoc(eventDocRef, { 
        phoneNumbers: updatedPhoneNumbers // Update Firestore with the new array
      }); // Update Firestore
    }
  } catch (error) {
    console.error("Error updating response number:", error);
  }
};

export const fetchSmsTemplateMessage = async () => { 
  const templateId = "zEgkyOHMc1n8OuUjrH9c"
  try {
    const templateDocRef = doc(db, "sms_templates", templateId);
    const templateSnapshot = await getDoc
    (templateDocRef);


    if (templateSnapshot.exists()) {
      const templateData = templateSnapshot.data();
      return templateData.Description || null; // Return the message key or null if it doesn't exist

    } else {
      console.log("No template found with the given ID");
      return null;
    }
  } catch (error) {
    console.error("Error fetching SMS template message:", error);
    return null;
  }
};

export const updateUserName = async (userId, newName) => { // {{ edit_1 }} - New function to update user name
  try {
    const userDocRef = doc(db, "users", userId); // Reference to the user document
    await updateDoc(userDocRef, { name: newName }); // Update the name field in Firestore
    return true;
  } catch (error) {
    console.error("Error updating user name:", error);
    return false;
  }
};

export const fetchEventDetailsById = async (eventId) => { // {{ edit_1 }} - New function to fetch event details by ID
  try {
    const eventDocRef = doc(db, "events", eventId); // Reference to the event document
    const eventSnapshot = await getDoc(eventDocRef); // Fetch the event document

    if (eventSnapshot.exists()) {
      return { id: eventSnapshot.id, ...eventSnapshot.data() }; // Return event ID and data
    } else {
      console.log(`No event found with ID: ${eventId}`);
      return null; // Return null if no event found
    }
  } catch (error) {
    console.error("Error fetching event details:", error);
    return null; // Return null in case of error
  }
};

export const convertMilitaryToRegularTime = (militaryTime) => {
  // Return early if invalid input
  if (!militaryTime || typeof militaryTime !== 'string') {
    return null;
  }

  try {
    // Split the time into hours and minutes
    const [hours, minutes] = militaryTime.split(':').map(Number);
    
    // Validate hours and minutes
    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return null;
    }

    // Convert to 12-hour format
    let period = hours >= 12 ? 'PM' : 'AM';
    let regularHours = hours % 12;
    regularHours = regularHours === 0 ? 12 : regularHours;
    
    // Pad minutes with leading zero if needed
    const paddedMinutes = minutes.toString().padStart(2, '0');
    
    return `${regularHours}:${paddedMinutes} ${period}`;
  } catch (error) {
    console.error('Error converting military time:', error);
    return null;
  }
};



