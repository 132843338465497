import Card from "components/card";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import QRCode from "react-qr-code";
import config from "utils/config";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { fetchLoggedInUserDetails } from "utils/utils";


export default function Qrcode({ bartenderName }) {
  const [loading, setLoading] = useState(false);

  
  const [textToCopy, setTextToCopy] = useState(
    config.INVITE_BASE_URL + `/auth/sign-up`
  );
  const currentUrl = window.location.href;
  const [QRCodeValue, setQRCodeValue] = useState(currentUrl);
  const [inviteCode, setInviteCode] = useState("");

  useEffect(() => {
  }, [inviteCode]);

  const populateData = async () => {
    setLoading(true);

    const auth = getAuth();
    const user = auth.currentUser;
    const userDetails = await fetchLoggedInUserDetails(user.phoneNumber);

    if (user) {
      try {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', user.phoneNumber);
        const userDocSnap = await getDoc(userDocRef);


        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          let inviteCode;
          inviteCode = userData.id;
          setInviteCode(inviteCode);



           
          if (currentUrl.includes('/events/') || currentUrl.includes('/auth/events/') || currentUrl.includes('/auth/workplace-home/') || currentUrl.includes('/auth/workplace/')) {
          } else {
            setTextToCopy(
              `${window.location.origin}/auth/regular-sign-up?invite=${inviteCode}`
            );
          }
          // setTextToCopy(`https://gliddy-backend.firebaseapp.com/auth/regular-sign-up?invite=${inviteCode}`);
        } else {
          console.error("User document not found");
          toast.error("Failed to generate invite link");
        }
      
      } catch (error) {
        console.error("Error fetching invite code:", error);
        toast.error("Failed to generate invite link");
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("User not authenticated");
    }
  };

  useEffect(() => {
    populateData();
  }, [textToCopy]);
  return (
    <Card>
      <Loading loading={loading} loaderColor="#f97316" />

      <div className="mt-[2rem] flex h-full max-w-[500px] items-center justify-center px-2 pt-[80px] pb-[80px] text-center m-auto">
        {/* Sign in section */}
        <div className="mt-[0vh] w-full max-w-full  flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <div className="text-center">
            <h2 className="text-center text-2xl font-bold text-navy-700 dark:text-white">
              {bartenderName ? "Scan to follow" : ``}
            </h2>
          </div>

          {bartenderName && (
            <div className="mt-[1rem] text-center">
              <h2 className="text-center text-2xl font-bold text-navy-700 dark:text-white">
                {bartenderName}
              </h2>
            </div>
          )}

          {/* Checkbox */}
          <div className="mt-[2rem] flex items-center justify-center px-2">
            <div className="flex items-center">
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "15px",
                  width: "100%",
                }}
              >
                  <QRCode
                    size={180}
                    fgColor="orange"
                    value={
                      `${window.location.origin}/auth/regular-sign-up?invite=${inviteCode}`
                    }
                    viewBox={`0 0 256 256`}
                  />
              </div>
            </div>
          </div>
          <div className="mt-4">
     
          </div>

          <div className="mt-4"></div>
        </div>
      </div>
    </Card>
  );
}
