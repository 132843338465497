import React, { useState, useEffect, useMemo, useRef } from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";
import Checkbox from "components/checkbox";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  updateDoc,
  arrayUnion,
  addDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc   } from "firebase/firestore"; // Import doc and setDoc functions
import {
  useLoadScript,
  GoogleMap,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
} from "use-places-autocomplete";

import { db } from "../../../../firebase/firebase";

const WorkplaceList = ({ type = "", headerTitle = "", buttonText = "" }) => {
  const APIKEY = "AIzaSyBKq68f-LDoW1GHbyg53382x0-p_csH38U";

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: APIKEY,
    libraries: ["places"], // Ensure 'places' is included
  });

  const navigate = useNavigate();
  const [barState, setBarState] = useState("Alabama");
  const [barName, setBarName] = useState("");
  const [workplaces, setWorkplaces] = useState([]);
  const [selectWorkplace, setSelectWorkplace] = useState("")
  const [loading, setLoading] = useState(false);






  function Map({ barState, setSelectWorkplace , workplace}) {
    const center = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
    const [selected, setSelected] = useState(barState);

    useEffect(() => {
    }, [barState]);
    return (
      <>
        <div className="places-container w-full">
          <PlacesAutocomplete state={barState} 
          setSelectWorkplace = {setSelectWorkplace}
          workplace={workplace}
          setSelected={setSelected}
          />
        </div>

        <GoogleMap
          zoom={10}
          center={center}
          mapContainerClassName="map-container"
        >
   {/* {selected && selected.lat && selected.lng && <Marker position={selected} />}         */}
   
   
   </GoogleMap>
      </>
    );
  }

  const PlacesAutocomplete = ({ setSelectWorkplace, workplace, setSelected }) => {
    const {
        ready,
        setValue,
        value, // Keep this for suggestions
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    const [inputValue, setInputValue] = useState(""); // State for input value
    const inputRef = useRef(null); // Ref for the input

    const handleSelect = async (address) => {
        setInputValue(address); // Set the input value to the selected address
        localStorage.setItem("selectedWorkplace", address);
        // setSelectWorkplace(address); // Only set the selected address
        if (inputRef.current) {
            inputRef.current.focus(); // Keep the input focused
        }
    };

    useEffect(() => {
    }, []);

    return (
        <div className="places-container w-full flex-1">
            <input
                ref={inputRef} // Attach ref to the input
                value={inputValue} // Controlled input using inputValue state
                onChange={(e) => {
                    setInputValue(e.target.value); // Update inputValue state
                    setValue(e.target.value); // Update usePlacesAutocomplete value
                }}
                disabled={!ready}
                className=" mt-2 flex h-12 w-full flex-1 items-center justify-center rounded-xl border border-gray-200 px-3 !pr-0 text-sm outline-none dark:!border-white/10  dark:text-white"
                placeholder="Search an address"
            />
            {status === "OK" && (
                <ul className="suggestions-list flex flex-col gap-[4px]">
                    {data.map(({ place_id, description }) => (
                        <li
                          className = "cursor-pointer pt-[10px]"
                            key={place_id}
                            onClick={(e) => {
                                e.preventDefault(); // Prevent default behavior
                                handleSelect(description);
                            }}
                        >
                            {description}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
  };


  const handleSearchKeyUp = async () => {
    if (barName.length >= 3) {
      setLoading(true);
      try {
        const workplacesRef = collection(db, "workplaces");
        const q = query(
          workplacesRef,
          where("state", "==", barState),
          where("name", ">=", barName),
          where("name", "<=", barName + "\uf8ff"),
          limit(10)
        );

        const querySnapshot = await getDocs(q);
        const newWorkplaces = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));


        setWorkplaces((prevWorkplaces) => {
          const uniqueWorkplaces = [...prevWorkplaces];
          newWorkplaces.forEach((newWorkplace) => {
            if (!uniqueWorkplaces.some((wp) => wp.id === newWorkplace.id)) {
              uniqueWorkplaces.push(newWorkplace);
            }
          });
          return uniqueWorkplaces;
        });
      } catch (error) {
        console.error("Error fetching workplaces:", error);
        toast.error("Failed to fetch workplaces");
      } finally {
        setLoading(false);
      }
    } else if (barName === "") {
      setWorkplaces([]);
    }
  };

  const handleDivClick = (index) => {
    const checkId = document.getElementById(`checkbox-${index}`);
    checkId.click();
  };


  useEffect(() => {}, [workplaces]);

  
  const handleSave = async () => {
    setLoading(true);
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) throw new Error("User not authenticated");

      const userPhoneNumber = currentUser.phoneNumber;

      if (!userPhoneNumber) throw new Error("User phone number not found");

      const userDocRef = doc(db, "users", userPhoneNumber);

      const selectedWorkplace = localStorage.getItem("selectedWorkplace");

      const workplaceData = {
        address: selectedWorkplace,
      };

      // Fetch the current user document
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();

      // Check if the workplace is new
      const isNewWorkplace = !userData.workplaces.some(
        (wp) => wp.address === selectedWorkplace
      );

      // Update the workplaces array and set currentWorkplace if it's a new workplace
      await updateDoc(userDocRef, {
        workplaces: arrayUnion(workplaceData),
        ...(isNewWorkplace && { currentWorkplace: selectedWorkplace }),
      });
      

      setLoading(false);

      toast.success("Workplaces added successfully");

      // Uncomment the following line if you want to navigate after saving
      navigate("/auth/workplace-home");
    } catch (error) {
      console.error("Error in handleSave:", error);
      setLoading(false);
      toast.error(error.message || "Failed to save workplaces");
    }
  };



  return (
    <Card extra={"!mt-[2rem] mb-10 !z-5 "}>
      <Loading loading={loading} loaderColor="#f97316" />

      {/* WorkplaceList Header */}
      <div className="ml-3 flex items-center justify-between rounded-t-3xl p-3">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          {headerTitle}
        </div>
      </div>
      <div className="flex">
        {/* <StateDropdown
          id="myStates"
          className="ml-[15px] mt-2 flex h-12 w-[93%] items-center justify-center rounded-xl border border-gray-200 p-3 text-sm outline-none dark:!border-white/10 dark:text-navy-700"
          value={barState} // Set the value to the currently selected state
          onChange={(state) => {
            setBarState(state); // Update the selected state
            setAddress(""); // Clear the address when state changes
            setSuggestions([]); // Clear suggestions when state changes
          }}
        /> */}
      </div>
      <div className="ml-[1rem] flex items-start justify-start">
        {isLoaded && (
          <Map barState={barState} setSelectWorkplace={setSelectWorkplace} />
        )}{" "}
   
        <button
          type="submit"
          onClick={() => handleSearchKeyUp()}
          className=" mr-[1rem] ml-[1rem] mt-2 mb-5  h-12 w-40  rounded-lg py-[12px] text-base  font-medium  text-orange-500 duration-200 dark:bg-navy-800 dark:text-orange-500 dark:hover:bg-navy-800 "
          style={{ borderWidth: 1, borderColor: "#f97316" }}
        >
          Search
        </button>
      </div>

      {/* Workplaces CardData */}
      <div className="list-scroll">
        {workplaces.map((data, index) => (
          <div
            key={index}
            className="flex w-full items-start justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700"
          >
            <div
              onClick={() => handleDivClick(index, data)}
              className="flex items-center gap-3"
            >
              <div className="flex h-16 w-16 items-center justify-center">
                <img
                  className="flex h-16 w-16 rounded-xl "
                  src={data.ImageURL}
                  alt=""
                />
              </div>

              <div className="flex w-56 flex-col">
                <h5 className="text-base font-bold text-navy-700 dark:text-white">
                  {" "}
                  {data.Workplace}
                </h5>
                <p className=" mt-1  text-sm font-normal text-gray-600">
                  {" "}
                  {data.Address}{" "}
                </p>
              </div>
            </div>
            <div className="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
              <div>
                {" "}
                <Checkbox
                  id={`checkbox-${index}`}
                  // onClick={(e) => handleSelectWorkplace(data, e)}
                />
              </div>
              <div className="ml-1 flex items-center text-sm font-bold text-navy-700 dark:text-white"></div>
              <div className="ml-2 flex items-center text-sm font-normal text-gray-600 dark:text-white"></div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-center justify-between">
        <button
          onClick={() => handleSave()}
          // disabled={selectWorkplace.length === 0 ? true : false}
          className={
            "linear w-90 m-4 mt-5 mb-5 w-full rounded-lg bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          }
        >
          {" "}
          {buttonText}
        </button>
      </div>
    </Card>
  );
};

export default WorkplaceList;
