import React, { useEffect, useState } from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import httpService from "services/http-service";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";
import { getAuth } from "firebase/auth";
import { db } from "../../../../firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import axios from "axios";

import {
  getLocalStorageValue,
  fetchUserRegulars,
  fetchRegularDetails,
  combineWorkplaceInfo,
} from "utils/utils";

import { GoogleMap, LoadScript, PlacesService } from "@react-google-maps/api";

const DeleteWorkplaces = ({ headerTitle = "", buttonText = "" }) => {
  const navigate = useNavigate();
  const [workplaces, setWorkplaces] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  // New state for users
  const user = getAuth();

  const [totalRegulars, setTotalRegulars] = useState(0);
  const [regulars, setRegulars] = useState([]);
  const [fullRegularDetails, setFullRegularDetails] = useState([]);
  const [isWorking, setIsWorking] = useState(true);
  const [workplaceName, setWorkplaceName] = useState([]);

  const userId = getLocalStorageValue("userDetails", "phoneNumber");
  const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_API_KEY;

  const fetchImageForWorkplace = (workplaceName) => {
    return new Promise((resolve, reject) => {
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      service.findPlaceFromQuery(
        {
          query: workplaceName,
          fields: ["photos"],
        },
        (results, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            results[0]
          ) {
            const photo = results[0].photos && results[0].photos[0]; // Check if photos exist
            if (photo) {
              const imageUrl = photo.getUrl({ maxWidth: 400 }); // Get the image URL
              resolve(imageUrl);
            } else {
              resolve(null); // No photo found
            }
          } else {
            resolve(null); // No image found
          }
        }
      );
    });
  };

  const fetchAndLogUserData = async () => {
    try {
      setLoading(true);
      const userRegulars = await fetchUserRegulars(userId);
      const userRegularDetails = await fetchRegularDetails(userId);
      fetchRegularDetails(userId);
      //Seteing the full reg detauls aswell
      setFullRegularDetails(userRegularDetails);
      setIsWorking(userRegularDetails.isWorking);

      setRegulars(userRegulars);
      setTotalRegulars(userRegulars.length);

      const workplacesWithImages = await Promise.all(
        userRegularDetails.workplaces.map(async (workplace) => {
          const imageUrl = await fetchImageForWorkplace(workplace.address); // Assuming workplace has a 'name' property
          return { address: workplace.address, image: imageUrl }; // Add address and image URL to workplace data
        })
      );
      setWorkplaceName(
        workplacesWithImages.map((workplace) => ({
          ...workplace,
          imageURL: workplace.image, // Append imageURL key
        }))
      );
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndLogUserData();
    localStorage.setItem("home", "/auth/workplace-home");
    localStorage.setItem("isWorking", isWorking);
  }, [userId, isWorking]);

  // React.useEffect(() => {
  //   const fetchWorkplaces = async () => {
  //     const workplaceString = await logWorkplaces(currentUser);
  //     setWorkplaces([workplaceString]);
  //   };
  //   fetchWorkplaces();
  //   // Call the new function to fetch users
  // }, [currentUser]);

  useEffect(() => {
    if (fullRegularDetails && fullRegularDetails.workplaces) {
      const workplace = fullRegularDetails.workplaces;

      setWorkplaceName(workplace);
      const combinedWorkplaceInfo = combineWorkplaceInfo(workplace);
      setWorkplaces([combinedWorkplaceInfo]);
      // console.log("workplace", workplace)

      // setWorkplaces(workplace)
      if (typeof workplace === "object" && !Array.isArray(workplace)) {
        const workPlaceAdress = workplace.address[0];
        const workPlaceName = workplace.workplace;
        setWorkplaceName(workPlaceAdress + " , " + workPlaceName);
      }
      // setWorkplaceName(`${workplace.Name}`);
    }
  }, [fullRegularDetails]);

  useEffect(() => {}, [workplaces]);

  const handleRemoveWorkplace = async (workplaceData) => {
    setLoading(true);
    try {
      // Find the workplace in the user's workplaces
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      // console.log("workplaceData", workplaceData)

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const workplaces = userData.workplaces || {};

        // Find the workplace key that matches the address and name

        const workplaceKey = Object.keys(workplaces).find((key) => {
          const workplace = workplaces[key];
          // console.log("LOgging the workplace address", workplace.address)
          // console.log("LOgging the workplace name", workplace.currentWorkplace)
          // console.log("LOgging the workplace data address", workplaceData.address)
          // console.log("LOgging the workplace data name", workplaceData.name)
          return (
            workplace.address === workplaceData.address &&
            workplace.currentWorkplace === workplaceData.name
          );
        });

        if (workplaceKey) {
          // Remove the workplace from the user's data

          // Update the user document

          // Simplified approach to remove workplace by address
          const updatedWorkplaces = Object.fromEntries(
            Object.entries(workplaces).filter(
              ([key, workplace]) => workplace.address !== workplaceData.address
            )
          );

          await updateDoc(userRef, { workplaces: updatedWorkplaces });
          delete workplaces[workplaceKey];

          // Update local state
          setWorkplaces((prevWorkplaces) =>
            prevWorkplaces.filter(
              (wp) =>
                wp &&
                wp.address === workplaceData.address &&
                wp.name === workplaceData.name // Added check for wp
            )
          );

          toast.success(
            `Workplace "${workplaceData.name}" has been removed successfully.`
          );
        } else {
          toast.error(`Workplace "${workplaceData.name}" not found.`);
        }
      } else {
        toast.error("User data not found.");
      }
    } catch (error) {
      console.error("Error removing workplace:", error);
      toast.error(
        `Failed to remove workplace "${workplaceData.name}". Please try again.`
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRouteClick = (page) => {
    navigate(page);
  };
  const handleRemoveWorkplaceByAddress = async (addressToRemove) => {
    setLoading(true);
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const workplaces = userData.workplaces || {};

        // Find the workplace key that matches the address
        const indexToRemove = workplaces.findIndex(
          (workplace) => workplace.address === addressToRemove.address
        );

        if (indexToRemove !== -1) {
          const removedWorkplace = workplaces[indexToRemove];
          workplaces.splice(indexToRemove, 1);

          // If the removed workplace is the current workplace, update the currentWorkplace
          if (userData.currentWorkplace === removedWorkplace.currentWorkplace) {
            userData.currentWorkplace = "";
          }

          await updateDoc(userRef, { workplaces, currentWorkPlace: userData.currentWorkplace });

          // Update local state to reflect the removal
          setWorkplaceName((prevWorkplaces) =>
            prevWorkplaces.filter(
              (workplace) => workplace.address !== addressToRemove.address
            )
          ); // This line updates the local state
        } else {
          toast.error("Workplace not found.");
        }
      } else {
        toast.error("User data not found.");
      }
    } catch (error) {
      console.error("Error removing workplace:", error);
      toast.error(
        `Failed to remove workplace at "${addressToRemove}". Please try again.`
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Workplace Name", workplaceName);
  }, [workplaceName]);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_PLACES_API_KEY} libraries={["places"]}>
      <Card extra={"!mt-[2rem] mb-10 !z-5 "}>
        <Loading loading={loading} loaderColor="#f97316" />
        {/* DeleteWorkplaces Header */}
        <div className="ml-3 flex items-center justify-between rounded-t-3xl p-3">
          <div className="text-lg font-bold text-navy-700 dark:text-white">
            {headerTitle}
          </div>
        </div>

        {/* History CardData */}
        {workplaces.length === 0 ? (
          <div className="pt-[100px] pb-[100px] text-center">
            <div classname="text-navy-700 dark:text-white">
              No workplaces found
            </div>
            <div classname="text-navy-700 dark:text-white">
              would you like to add one?
            </div>
          </div>
        ) : (
          <div className="list-scroll-d">
            {workplaceName.map((data, index) => (
              <div
                key={index}
                className="flex w-full items-start justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700"
              >
                <div className="flex items-center gap-3">
                  <div className="flex h-16 w-16 items-center justify-center">
                    <img
                      className="h-full w-full rounded-xl"
                      src={data.image} // Assuming 'data' has an 'image' property
                      alt=""
                    />
                  </div>
                  <div className="flex w-56 flex-col">
                    <h5 className="text-base font-bold text-navy-700 dark:text-white">
                      {data.address}{" "}
                      {/* Ensure 'data' has an 'address' property */}
                    </h5>
                    <p className="mt-1 text-sm font-normal text-gray-600">
                      {data.name} {/* Ensure 'data' has a 'name' property */}
                    </p>
                  </div>
                </div>
                <div className="mt-1 ml-[-15px] flex items-center justify-center text-navy-700 dark:text-white">
                  <div>
                    <button
                      onClick={() => handleRemoveWorkplaceByAddress(data)}
                    >
                      {" "}
                      {/* Pass the correct workplace data */}
                      <MdDeleteForever className="mt-1 h-6 w-6" />
                    </button>
                  </div>
                  <div className="ml-1 flex items-center text-sm font-bold text-navy-700 dark:text-white"></div>
                  <div className="ml-2 flex items-center text-sm font-normal text-gray-600 dark:text-white"></div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex items-center justify-between">
          <button
            onClick={() =>
              handleRouteClick(workplaces.length === 0 ? "/auth/workplace" : 0)
            }
            className="linear w-90 m-4 mt-5  mb-5 w-full rounded-lg bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            {" "}
            {workplaces.length === 0 ? "Add New Workplace" : buttonText}
          </button>
        </div>
      </Card>
    </LoadScript>
  );
};

export default DeleteWorkplaces;
