import React from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import {
  getLocalStorageValue,
  formatDate,
  fetchUserRegulars,
  fetchAllRegularsDetails,
  countRegulars,
  fetchSmsTemplateMessage,
  fetchRegularDetails
} from "utils/utils";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import HeaderEvent from "components/header/HeaderEvent";
import EventForm from "components/events/evenform";
import EventForm2 from "components/events/eventform2";
import EventForm3 from "components/events/eventform3";

import {
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { getAuth } from "firebase/auth";

import {
  useJsApiLoader,
  GoogleMap,
} from "@react-google-maps/api";


import { formatDateLong } from "utils/utils";

export default function Events() {
  const APIKEY = process.env.REACT_APP_API_KEY;
  const userName = getLocalStorageValue("userDetails", "name");

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: APIKEY,
    libraries: ["places"],
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [smsStatus, setSmsStatus] = useState(null);
  const [regulars, setRegulars] = useState(0);
  const [totalRegulars, setTotalRegulars] = React.useState(0);
  const [regularDetails, setRegularDetails] = React.useState([]);
  const [regularPhoneNumbers, setRegularPhoneNumbers] = useState([]);
  const [templateMessage, setTemplateMessage] = useState("");
  const [fullRegularDetails, setFullRegularDetails] = useState([]);



  const [IsLoaded, setIsLoaded] = useState(false);


  const userId = getLocalStorageValue("userDetails", "phoneNumber");

  const fetchAndLogUserData = async () => {
    try {
      setLoading(true);
      const userRegulars = await fetchUserRegulars(userId);
      const userRegularDetails = await fetchRegularDetails(userId);
      fetchRegularDetails(userId);
      //Seteing the full reg detauls aswell
      setFullRegularDetails(userRegularDetails);
    


      setRegulars(userRegulars);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

 
  //Rerender to fetch the regular details
  useEffect(() => {
    fetchAndLogUserData();
  }, []);
  
  useEffect(() => {
    if(regulars){
      const count = countRegulars(regulars);
      setTotalRegulars(count);
      

      const fetchRegularsDetails = async () => {
        const regularNames = await fetchAllRegularsDetails(regulars);
        
        setRegularDetails(regularNames.mappedUsers);

        // Convert object to array and extract phone numbers
        const phoneNumbers = Object.values(regularNames.mappedUsers)
          .map(regular => regular.phoneNumber)
          .filter(Boolean);
        
        setRegularPhoneNumbers(phoneNumbers);
      };
      fetchRegularsDetails();
    }
  }, [regulars]);

  //checking the total regulars 
  useEffect(() => { 


  },[totalRegulars])

  const [smsMessage, setSmsMessage] = useState("");



  // Call the function where appropriate, e.g., in useEffect or a button click
  useEffect(() => {
    // fetchSmsTemplates(); // Fetch SMS templates when the component mounts
    const fetchTemplate = async () => {
      const templateMessage = await fetchSmsTemplateMessage();
      setTemplateMessage(templateMessage);
    };
    fetchTemplate();
  }, []);


  useEffect(() => {
  }, [templateMessage])

  useEffect(() => {}, [smsMessage, templateMessage]);


  const convertToStandardTime = (militaryTime) => {
    const [hours, minutes] = militaryTime.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';
    const standardHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${standardHours}:${minutes} ${period}`;
  };



  const sendSMS = async (eventId) => {
    setIsSending(true);
    setSmsStatus(null);

    if (!userId) {
      setSmsStatus("Error: Sender ID is missing");
      setIsSending(false);
      return;
    }
    // console.log("templateMessage", convertToStandardTime(eventData.time))

    const message = templateMessage
    .replace("${userName}", userName)
    .replace("${eventLocation}", eventData.location)
    .replace("${eventName}", eventData.name)
    .replace("${eventTime}", convertToStandardTime(eventData.time) )
    .replace("${eventDescription}", eventData.description)
    .replace(/\\n/g, "\n"); // Replace escaped newlines with actual newlineshow






    try {
      const response = await fetch(
        "https://b1muqhevw3.execute-api.us-east-1.amazonaws.com/dev/GliddyFunction",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            senderId: userId,
            phoneNumbers: regularPhoneNumbers,
            message: message,
         
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setSmsStatus("SMS sent successfully to all recipients");
      } else {
        setSmsStatus(
          `Failed to send SMS: ${result.message || "Unknown error"}`
        );
      }
    } catch (error) {
      setSmsStatus(`Error: ${error.message}`);
    } finally {
      setIsSending(false);
    }
  };


  //Send ussers who are not staff back to regular-sign in our wherever
  useEffect(() => {
    if (!userId) {
      navigate("/auth/regular-sign-in");
    }
  }, []);




  //Capturing the event data

  const [eventData, setEventData] = useState(() => {
    const passedData = location.state?.eventFormData;
    return {
      name: passedData?.name || passedData?.title || "",
      date: passedData?.date || "",
      time: passedData?.time || "",
      location: passedData?.location || "",
      description: passedData?.description || "",
      smsReminder: passedData?.smsReminder ?? true,
    };
  });


  useEffect(() => {
    // console.log("LOgging formdata", eventData)
  }, [eventData])

  // Add useEffect to handle data changes
  useEffect(() => {
    if (location.state?.eventFormData) {
      // Log the incoming data
      // console.log('Received flyer data:', location.state.eventFormData);
      
      // Update form with scanned data
      setEventData(prev => ({
        ...prev,
        ...location.state.eventFormData
      }));

      // Optional: Start at specific form step
      setCurrentStep(1); // Or whichever step you want to start at
    }
  }, [location.state]);

  //Submit the form placeholder
  const handleFormChange = (newData) => {
    setEventData((prevData) => ({ ...prevData, ...newData }));
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error("No authenticated user found. Please sign in and try again.");
      }

      // Format date and time as before
      const formattedDate = formatDate(eventData.date);
      const parsedTime = dayjs(eventData.time, ["HH:mm", "h:mm A", "hh:mm A"], true);

   
      // if (!parsedTime.isValid()) {
      //   throw new Error("Invalid time format. Please enter a valid time.");
      // }

      const formattedTime = parsedTime.format("HH:mm");
      const dateTimeString = `${formattedDate}T${formattedTime}:00`;
      
      // Create event in Firebase
      const eventsRef = collection(db, "events");
      const newEvent = {
        title: eventData.name,
        description: eventData.description,
        date: new Date(dateTimeString),
        location: eventData.location,
        createdBy: userId,
        isActive: true,
        createdDate: serverTimestamp(),
        lastModifiedDate: serverTimestamp(),
        smsReminder: eventData.smsReminder,
        invitesSent: 0,
        responseNumber: 0,
        goingNumber: 0,
        maybeNumber: 0,
        notGoingNumber: 0,
        phoneNumbers: [],
      };

      const docRef = await addDoc(eventsRef, newEvent);
      const eventId = docRef.id;

   
      // Send immediate SMS
      await sendSMS(eventId);

      // Schedule reminder if smsReminder is enabled
      if (eventData.smsReminder) {
        // Calculate reminder time (24 hours before event)
        const eventDateTime = new Date(dateTimeString);
        const reminderTime = new Date(eventDateTime.getTime() - (24 * 60 * 60 * 1000));




        // Prepare reminder message
        const message = templateMessage
          .replace("${userName}", userName)
          .replace("${eventLocation}", eventData.location)
          .replace("${eventName}", eventData.name)
          .replace("${eventTime}", eventData.time)
          .replace("${eventDescription}", eventData.description)
          .replace(/\\n/g, "\n");

        // Schedule the reminder
        const reminderResponse = await fetch(
          "https://b1muqhevw3.execute-api.us-east-1.amazonaws.com/dev/GliddyFunction",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              senderId: userId,
              phoneNumbers: regularPhoneNumbers,
              message: message,
              scheduledTime: reminderTime.toISOString(),
              eventId: eventId,
              reminderEvent: true
            }),
          }
        );

        if (!reminderResponse.ok) {
          console.error("Failed to schedule reminder");
          toast.warning("Event created but reminder scheduling failed");
        }
      }

      setLoading(false);
      navigate("/auth/events");
      
    } catch (error) {
      console.error("Error creating event:", error);
      toast.error("Failed to create event");
      setLoading(false);
    }
  };

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    // Initialize with your form fields
    title: "",
    description: "",
    date: "",
    // Add more fields as needed
  });
  const onContinue = () => {
    if (currentStep < 3) {
      // Move to the next step
      setCurrentStep(currentStep + 1);
      //   toast.info(`Moved to step ${currentStep + 1}`);
    } else {
      // Final step: log form data and reset
      //   toast.success('Event created successfully! (Dummy action)');
      // Reset form
      setFormData({
        title: "",
        description: "",
        date: "",
        // Reset other fields
      });
      setCurrentStep(1);
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {}, [currentStep]);

  const handleContinue = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
    onContinue();
  };

  const [isGoogleMapsReady, setIsGoogleMapsReady] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      const timer = setTimeout(() => {
        setIsGoogleMapsReady(true);
      }, 1000); // Adjust the timeout duration as needed

      // console.log("isGoogleMapsReady", isGoogleMapsReady)
      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [isLoaded]);



  return (
    <div className="m-auto w-[340px] md:max-w-[500px]">
      {loading && <Loading loading={loading} loaderColor="#f97316" />}
      {!isLoaded ? (
        <Loading loading={true} loaderColor="#f97316" />
      ) : (
        <>
          <div className="flex flex-col gap-[13px]">
            <HeaderEvent regHome={false} regCreate={true} />
            <ProgressBar currentStep={currentStep} />
          </div>



          {currentStep === 1 && (
            <EventForm
              title={"Describe your event"}
              description={"Describe your event and share with regulars instantly"}
              onContinue={handleContinue}
              onBack={handleBack}
              formData={eventData}
              onFormChange={handleFormChange}
              isLoaded={isLoaded}
            />
          )}
          {currentStep === 2 && (
            <EventForm2
              title={"Do you want to send auto-reminders to regulars via SMS?"}
              description={"Tuesday, May 5, 2020"}
              onContinue={handleContinue}
              onBack={handleBack}
              formData={eventData}
              onFormChange={handleFormChange}
            />
          )}
          {currentStep === 3 && (
            <>
              <EventForm3
                title={"Trivia Hawaii Night"}
                description={"Tuesday, May 5, 2020"}
                time={"6:00 PM"}
                onContinue={handleContinue}
                onBack={handleBack}
                formData={eventData}
                onFormChange={handleFormChange}
                latitude={37.7749}
                longitude={-122.4194}
                isLoaded={isLoaded}
              />
              <div className="mt-[50px] flex flex-col gap-[10px]">
                <button
                  onClick={handleSubmitForm}
                  className="linear h-[70px] w-full rounded-xl bg-brand-500 px-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Finish and Send Invites
                </button>
                <button
                  onClick={handleBack}
                  className="linear text-black text-black bg-transparent mb-5 h-[55px] w-full rounded-xl border-[2px] border-[#000] px-2 text-base font-medium"
                >
                  Back
                </button>
              </div>
            </>
          )}
        </>
      )}
  
    </div>
  );
}

const ProgressBar = ({ currentStep }) => {
  const getProgressWidth = () => {
    switch (currentStep) {
      case 1:
        return "10%";
      case 2:
        return "50%";
      case 3:
        return "100%";
      default:
        return "0%";
    }
  };

  return (
    <div className="mb-6 h-2 w-full rounded-full bg-white">
      <div
        className="h-full rounded-full bg-brand-500 transition-all duration-300 ease-in-out"
        style={{ width: getProgressWidth() }}
      ></div>
    </div>
  );
};
