import React from "react";
import GenericModal from "views/admin/default/components/GenericModal";
import { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PencilIcon from "../../../assets/img/icons/pencil-square.png";
import { useParams } from "react-router-dom";
// Compoonents that we will be using
import HeaderEvent from "components/header/HeaderEvent";
import Loading from "react-fullscreen-loading";
import Invite from "views/auth/Invite";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowLeft from "assets/img/icons/arrow-left.svg";
import { Link } from "react-router-dom";
import { db } from "../../../firebase/firebase";
import {
  collection,
  query,
  getDocs,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { getLocalStorageObject, getLocalStorageValue, fetchSmsTemplateMessage, fetchAllRegularsDetails, countRegulars, fetchUserRegulars, fetchRegularDetails } from "utils/utils";
import { Timestamp } from 'firebase/firestore';

const EventDetails = ({
  title,
  description,
  onContinue,
  onBack,
  modalContent,
  latitude,
  longitude,
}) => {
  const navigate = useNavigate();
  const mapContainerStyle = {
    width: "100%",
    height: "150px",
  };

  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });

  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const userObject = getLocalStorageObject("userDetails");

  const [regulars, setRegulars] = useState(null);
  const { id } = useParams();

  const APIKEY = "AIzaSyBKq68f-LDoW1GHbyg53382x0-p_csH38U";



  const userId = getLocalStorageValue("userDetails", "phoneNumber");
  const userName = getLocalStorageValue("userDetails", "name");
  const [regularPhoneNumbers, setRegularPhoneNumbers] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [smsStatus, setSmsStatus] = useState(null);
  const [templateMessage, setTemplateMessage] = useState("");
  const [regularDetails, setRegularDetails] = React.useState([]);
  const [totalRegulars, setTotalRegulars] = React.useState(0);
  const [fullRegularDetails, setFullRegularDetails] = useState([]);



  const fetchAndLogUserData = async () => {
    try {
      setLoading(true);
      const userRegulars = await fetchUserRegulars(userId);
      const userRegularDetails = await fetchRegularDetails(userId);
      fetchRegularDetails(userId);
      //Seteing the full reg detauls aswell
      setFullRegularDetails(userRegularDetails);
    


      setRegulars(userRegulars);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

 
  //Rerender to fetch the regular details
  useEffect(() => {
    fetchAndLogUserData();
  }, []);
  
  useEffect(() => {
    if(regulars){
      const count = countRegulars(regulars);
      setTotalRegulars(count);
      

      const fetchRegularsDetails = async () => {
        const regularNames = await fetchAllRegularsDetails(regulars);
        
        setRegularDetails(regularNames.mappedUsers);

        // Convert object to array and extract phone numbers
        const phoneNumbers = Object.values(regularNames.mappedUsers)
          .map(regular => regular.phoneNumber)
          .filter(Boolean);
        
        setRegularPhoneNumbers(phoneNumbers);
      };
      fetchRegularsDetails();
    }
  }, [regulars]);

  //checking the total regulars 
  useEffect(() => { 


  },[totalRegulars])

 

    // Call the function where appropriate, e.g., in useEffect or a button click
    useEffect(() => {
      // fetchSmsTemplates(); // Fetch SMS templates when the component mounts
      const fetchTemplate = async () => {
        const templateMessage = await fetchSmsTemplateMessage();
        setTemplateMessage(templateMessage);
      };
      fetchTemplate();
    }, []);


  
  
  const sendSMS = async (eventId) => {
    setIsSending(true);
    setSmsStatus(null);

    if (!userId) {
      setSmsStatus("Error: Sender ID is missing");
      setIsSending(false);
      return;
    }
    console.log("templateMessage", templateMessage)
    const message = templateMessage
    .replace("${userName}", userName)
    .replace("${eventLocation}", event.location)
    .replace("${eventName}", event.name)
    .replace("${eventTime}", event.time)
    .replace("${eventDescription}", event.description)
    .replace(/\\n/g, "\n"); // Replace escaped newlines with actual newlineshow

    // console.log("LOgging the Sender Id", userId)
    // console.log("LOgging the phone numebr", regularPhoneNumbers)
    // console.log("LOgging the message", message)



    try {
      const response = await fetch(
        "https://b1muqhevw3.execute-api.us-east-1.amazonaws.com/dev/GliddyFunction",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            senderId: userId,
            phoneNumbers: regularPhoneNumbers,
            message: message,

          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setSmsStatus("SMS sent successfully to all recipients");
        toast.success("SMS sent successfully to all recipients");
      } else {
        setSmsStatus(
          `Failed to send SMS: ${result.message || "Unknown error"}`
        );
      }
    } catch (error) {
      setSmsStatus(`Error: ${error.message}`);
    } finally {
      setIsSending(false);
    }
  };



  //Delete the event the database;
  const handleDeleteEvent = async () => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        const eventDoc = doc(db, "events", id);
        await deleteDoc(eventDoc);
        console.log("Event deleted successfully");
        navigate("/auth/events");
      } catch (error) {
        console.error("Error deleting event:", error);
        alert("Error deleting event:", error);
      }
    }
  };

  //Now we fetch events from the database

  useEffect(() => {
    const fetchEvents = async () => {
      if (!userObject || !userObject.phoneNumber) {
        console.error("User ID not found");
        setLoading(false);
        return;
      }

      try {
        const eventsRef = collection(db, "events");
        const q = query(
          eventsRef,
          where("createdBy", "==", userObject.phoneNumber)
        );
        const querySnapshot = await getDocs(q);

        const fetchedEvents = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.title,
            date: data.date
              .toDate()
              .toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              }),
            time: data.date
              .toDate()
              .toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              }),
            description: data.description,
            location: data.location,
            responseRate: data.responseRate || 0,
            invited: data.invited || 0,
            going: data.going || 0,
            maybe: data.maybe || 0,
            notGoing: data.notGoing || 0,
          };
        });

        // console.log("Fetched events:", fetchedEvents);

        setEvents(fetchedEvents);
        console.log("Fetched events:", fetchedEvents);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    if (isLoaded && event.location) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: event.location }, (results, status) => {
        if (status === "OK" && results[0]) {
          const { lat, lng } = results[0].geometry.location;
          setCenter({ lat: lat(), lng: lng() });
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
          // Fallback to default center if geocoding fails
          setCenter({ lat: 38.3032, lng: -77.4605 });
        }
      });
    }
  }, [isLoaded]);

  useEffect(() => {
    if (events.length > 0 && id) {
      const selectedEvent = events.find((event) => event.id === id);
      setEvent(selectedEvent);
    }
  }, [id, events]);

  useEffect(() => {
    // const event = events.find((event) => event.id === id);
    // const { name, date, time } = event; // Extracting relevant fields
  }, [id, events]);

  const editEvent = () => {
    console.log("This is the id", id);

    navigate(`/auth/events/createEvent/${id}`);
  };

  if (loading) {
    return <Loading />;
  }

  if (!event) {
    return <div>Event not found {id}</div>;
  }

  // Check local storage for isWorking value
  const isWorking = localStorage.getItem("isWorking");



  return (
    <div
      className={`relative   float-right  h-full min-h-screen w-full ${
        isWorking ? "!bg-white" : "  !bg-navy-900 !text-white"
      }`}
    >
      <main className={`mx-auto min-h-screen`}>
        <div className="relative flex"> 
          <div className="mx-auto flex min-h-full  w-full flex-col justify-start pt-0 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
            <div
              className={
                "mb-auto flex max-h-[100vh] flex-col overflow-y-auto overflow-x-hidden xs:pr-1  sm:pl-5 sm:pr-5 md:pr-5 md:pl-5  lg:pl-0 xl:max-w-full"
              }
            >
              <div className="m-auto sm:p-r-[20px]  smd:flex-row flex flex-col  w-[340px] sm:w-[340px] max-w-[500px]">
                <HeaderEvent
                  tagline="Regulars"
                  regHome={false}
                  regCreate={false}
                  totalRegulars={totalRegulars}
                />


      <Link
        to="/auth/events"
        className="flex flex-row items-center justify-start gap-[20px]"
      >
        <img src={ArrowLeft} alt="arrow-left" />
        <p className="text-[15px] font-[600]">Back</p>
      </Link>

                <div className="flex flex-col gap-[10px] sm:pr-[20px] pr-0">
                  <div className="flex flex-col gap-[16px]">
                    <div className="flex flex-row justify-between gap-[16px]">
                      <div className="flex flex-col justify-between">
                        <h1 className="text-[27px] font-[600] text-[#0F172A]">
                          {event.name}
                        </h1>
                        <div className="mt-[5px] flex flex-col gap-[5px]">
                          <p className="text-[16px] text-[#000000]">
                            {event.date}
                          </p>
                          <p className="text-[16px] text-[#000000]">
                            {event.time}
                          </p>
                        </div>
                      </div>

                      <buttton className="cursor-pointer" onClick={editEvent}>
                        <img src={PencilIcon} alt="Pencil Icon" />
                      </buttton>
                    </div>
                    <LoadScript
     googleMapsApiKey={APIKEY}
     onLoad={() => setIsLoaded(true)}
     onError={() => console.error("Error loading Google Maps API")}
   >
                      <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        zoom={14}
                      >
                        <Marker position={center} />
                      </GoogleMap>
                    </LoadScript>

                    <div className="flex flex-col gap-[5px]">
                      <h2 className="text-[16px] font-semibold text-[#000000]">
                        {event.location}
                      </h2>
                      <p className="text-[14px] text-[#64748B]">
                        {event.location}
                      </p>
                    </div>
                  </div>
                  <div className="mt-[25px] flex flex-col gap-[13px]">
                    <p className="text-[16px] uppercase text-[#64748B]">
                      Details
                    </p>

                    <p className="text-[20px] text-[#000]">
                      {event.description}
                    </p>
                  </div>

                  <div className="mt-[25px] flex flex-col gap-[8px]">
                    <button
                      onClick={() => sendSMS(event.id)}
                      className="linear text-black text-black  bg-transparent h-[62px] w-full rounded-xl border-[2px] border-[#000]  px-2 text-base font-medium"
                    >
                      Send to all regulars via SMS
                    </button>
                 
                    <div className="flex justify-between self-start w-full">
              <GenericModal
              className="!w-full"
                modalTrigger={
                  <button
                  onClick={onBack}
                  className="linear text-black text-black   bg-transparent h-[62px] w-[340px] smd:w-[340px] rounded-xl border-[2px] border-[#000]  px-2 text-base font-medium"
                >
                  Share event
                </button>

                }
                modalContent={
                  <Invite
                    title="Bring in other industry folks"
                    description="Your fellow bartenders will appreciate it, and so will we. Copy the link below."
                  />
                }
              />
</div>

                    <button
                      onClick={handleDeleteEvent}
                      className="mt-[20px] font-medium text-[#EF4444]"
                    >
                      Delete event
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default EventDetails;
