import { useLocation, useNavigate } from "react-router-dom";
import Header from "components/header/HeaderAuthDefault";
import httpService from "services/http-service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";

//Firebase configuration 
import {auth, db} from '../../firebase/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { getAuth } from "firebase/auth";
import { getLocalStorageValue, fetchRegularDetails } from "utils/utils";


const mask = "+1 (999) 999-9999";
const maskGenerator = createDefaultMaskGenerator(mask);





export default function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isRegular, setIsRegular] = useState(null);
  const isStaff = getLocalStorageValue("userDetails", "isStaff")
  const [phoneNumber, setphoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const followId = parseInt(window.location.hash.replace("#", ""));
  const [followName, setFollowName] = useState("");
  const [followLink, setFollowLink] = useState("");
  const [recaptchaReady, setRecaptchaReady] = useState(false);

  const user = getAuth().currentUser;
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    console.log("User", user);
    setCurrentUser(user);
  }, [user, currentUser]);


  //Lets capture our token 
  const [token, setToken] = useState(null);
  useEffect(() => {
    const localToken = localStorage.getItem("token");
    if (localToken) {
      setToken(localToken);
    }

  async function reDirectUser() {
    if (token || user) {
      console.log("Current user", currentUser);
      const currentUserPhoneNumber = currentUser.phoneNumber
      const currentUserDetails = await fetchRegularDetails(currentUserPhoneNumber);



      console.log("Current user details", currentUserDetails);
  

      if(currentUserDetails.isStaff === true) {
      navigate("/auth/workplace-home", {
        state: {
          isRegular: isRegular,
          phoneNumber: phoneNumber,
          verificationId: token,
          confirmationResult: token
        },
      });
    } else if (currentUserDetails.isRegular === true) {
      
      navigate("/auth/customerHome", {
        state: {
          isRegular: isRegular,
          phoneNumber: phoneNumber,
          verificationId: token,
          confirmationResult: token
        },
      });
    }

  }
  }


  reDirectUser()
  
  }, [token]);



  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const formatAndValidatePhoneNumber = (phoneNumber) => {
    const digitsOnly = phoneNumber.replace(/\D/g, "");
    const formattedNumber = "+1" + digitsOnly;
    const e164Regex = /^\+[1-9]\d{7,14}$/;
    
    if (e164Regex.test(formattedNumber)) {
      console.log("Phone number is valid E.164 format:", formattedNumber);
      return formattedNumber;
    } else {
      console.error("Invalid phone number format:", formattedNumber);
      return null;
    }
  };

  const handleLogin = async () => {
    if (phoneNumber) {
      // setLoading(true);
      try {
        if (!recaptchaReady) {
          if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
              auth,
              'recaptcha-container',
              {
                size: 'normal',
                hl: 'en',
                callback: () => {
                  setRecaptchaReady(true);
                  handleLogin();
                },
                'expired-callback': () => {
                  toast.error("reCAPTCHA expired. Please try again.");
                  setLoading(false);
                }
              },
              auth
            );
          }
          window.recaptchaVerifier.render();
          return;
        }

        const formattedPhoneNumber = formatAndValidatePhoneNumber(phoneNumber);

        if (!formattedPhoneNumber) {
          throw new Error("Invalid phone number format");
        }

        const appVerifier = window.recaptchaVerifier;

        const confirmationResult = await signInWithPhoneNumber(
          auth,
          formattedPhoneNumber,
          appVerifier
        );

        window.confirmationResult = confirmationResult;
        localStorage.setItem('confirmationResult', JSON.stringify(confirmationResult));

        localStorage.setItem("userDetails", JSON.stringify({
          phoneNumber: formattedPhoneNumber,
          isRegular: isRegular,
          isStaff: isStaff,
          referredByBartenderId: followId
        }));

        toast.success("Verification code sent successfully");
        localStorage.setItem('verificationId', confirmationResult.verificationId);

        console.log("we redicted");
        navigate("/auth/verify", {
          state: {
            isRegular: isRegular,
            phoneNumber: formattedPhoneNumber,
            verificationId: confirmationResult.verificationId,
            confirmationResult: confirmationResult
          },
        });
      } catch (error) {
        setLoading(false);
        console.error("Error during phone authentication:", error);
        toast.error("Error sending verification code: " + error.message);
      }
    } else {
      toast.error("Please enter a valid phone number.");
    }
  };

  const fetchBartenderName = async () => {
    setLoading(true);
    const endpoint = `/bartenders/getBartenderName/${followId}`;
    const response = await httpService("GET", endpoint);
    if (response.Error === "HttpException") {
      setLoading(false);
    } else {
      setLoading(false);
      setFollowName(response);
    }
  };

  return (
    <div className="flex pt-6 h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      <Loading loading={loading} loaderColor="#f97316" />
      {/* Sign in section */}
      <div className="mt-0 w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <Header
          tagline={
            isRegular
              ? `Welcome back! ${followName && "You are following..."}`
              : "See your regulars more regularly"
          }
        />
        {followName && (
          <h4 className="mb-10 mt-[-30px] text-2xl font-bold text-navy-700 dark:text-white">
            {followName}
          </h4>
        )}
        <h4 className="mb-2.5 text-2xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="text-base font-medium text-navy-700 dark:text-white">
          We'll send a confirmation code to this number to verify your account.
        </p>
        {/* Phone */}
        <MaskedInput
          autoFocus
          className="dark:text-black mt-4 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 p-3 text-sm outline-none dark:!border-white/10"
          placeholder="Phone Number"
          inputMode="numeric"
          pattern="\d*"
          onKeyDown={handleKeyDown}
          maskGenerator={maskGenerator}
          value={phoneNumber}
          onChange={(event) => setphoneNumber(`${event}`)}
        />

        <button
          disabled={phoneNumber.length !== 10}
          onClick={() => handleLogin()}
          className={
            phoneNumber.length === 10
              ? "linear mt-10 mb-[10px] w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              : "linear mt-10 mb-[10px] w-full cursor-not-allowed rounded-xl bg-gray-500 py-[12px] text-base font-medium text-white opacity-50 transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          }
        >
          Send verification code
        </button>

        <div id="recaptcha-container"></div>

        <h4 className="mt-5 items-center justify-center font-bold text-navy-700 dark:text-white">
          &nbsp; Don't have an account?{" "}
          <button
            className="text-brand-500"
            onClick={() =>
              handleRouteClick(
                isRegular
                  ? `/auth/regular-sign-up/#${followLink}`
                  : "/auth/sign-up"
              )
            }
          >
            {" "}
            &nbsp; Sign Up{" "}
          </button>
        </h4>
        <div className="signIn-notes mt-8">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
            We'll send you a verification code by SMS. Standard rates may apply.
            By continuing, I agree to Gliddy's
          </span>
          <a
            href="https://meetgliddy.com/terms-of-service"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Terms of Service.
          </a>
          <span className=" ml-1 text-sm font-medium text-navy-700 dark:text-gray-600">
            Please see Gliddy's
          </span>
          <a
            href="https://meetgliddy.com/privacypolicy"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Privacy Notice
          </a>
          <span className=" ml-1 text-sm font-medium text-navy-700 dark:text-gray-500">
            regarding its privacy practies.
          </span>
        </div>
      </div>
      <div 
      className = "my-[10px]"
      id="recaptcha-container"></div>
    </div>
  );
}
