import React, { useEffect, useState, useMemo } from 'react';

import DatePicker from 'react-datepicker';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { toast } from "react-toastify";



import {
  getLocalStorageValue,
  fetchRegularDetails,
  convertMilitaryToRegularTime
} from "utils/utils"

import {
  useLoadScript,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";



import "react-datepicker/dist/react-datepicker.css";

const EventForm = ({ title, description, onContinue, modalContent, formData, onFormChange, APIKEY, isLoaded }) => {


dayjs.extend(customParseFormat);





const [barState, setBarState] = useState("Alabama");
const [selectWorkplace, setSelectWorkplace] = useState("")
const [fullRegularDetails, setFullRegularDetails] = useState([]);
const [workplaceName, setWorkplaceName] = useState("");
const [loading, setLoading] = useState(true);

const userId = getLocalStorageValue("userDetails", "phoneNumber");

// const { isLoaded } = useLoadScript({
//   googleMapsApiKey: APIKEY, // Ensure this is uncommented and valid
//   libraries: ["places"], // Ensure 'places' is included
// });

useEffect(() => {
  if (workplaceName) {
    formData.location = workplaceName; // Set formData.location to workplaceName
  }
}, [workplaceName]); 


function Map({ barState, setSelectWorkplace, workplaceName , onFormChange, isLoaded}) {
  const center = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
  const [selected, setSelected] = useState(null);


  return (
    <>
    {isLoaded && (
      <>
      <div className="places-container w-full">
        <PlacesAutocomplete state={barState} 
        setSelectWorkplace = {setSelectWorkplace}
        workplaceName={workplaceName}
        onFormChange={onFormChange}
        setSelected={setSelected}
        />
        </div>

      <GoogleMap
        zoom={10}
        center={center}
        mapContainerClassName="map-container"
      >
        {selected && <Marker position={selected} />}
        </GoogleMap>
        </>
      )}
    </>
  );
}
const PlacesAutocomplete = ({ setSelectWorkplace, workplaceName, onFormChange, setSelected }) => {
  const {
    ready,
    setValue,
    value,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  useEffect(() => {
    // Set the initial value based on formData.location or workplaceName
    if (formData.location) {
      setValue(formData.location, false);
      setWorkplaceName(formData.location, false);
    } else if (workplaceName) {
      setValue(workplaceName, false);
    }
  }, [formData.location, workplaceName]); // Added dependencies

  const handleSelect = async (address) => {
 
    
    // Check if the new address is different from the current value
    if (value !== address) {
      setValue(address, false); // Update local state
      
      // Get the geocode for the selected address
      
      try {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setSelected({ lat, lng }); // Set the selected position with valid coordinates
        
        onFormChange({ location: address }); // Ensure this updates the parent state
        setWorkplaceName(address, false);
      } catch (error) {
        console.error("Error getting geocode:", error);
      }
    }

    clearSuggestions();
  };

  return (
    <div className="places-container w-full flex-1">
      <input
        value={value} // Ensure
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className=" mt-2 flex h-12 w-full flex-1 items-center justify-center rounded-xl border border-gray-200 px-3 !pr-0 text-sm outline-none dark:!border-white/10 dark:text-navy-700 dark:text-white"
        placeholder="Search an address"
      />
      {status === "OK" && (
        <ul className="suggestions-list">
          {data.map(({ place_id, description }) => (
            <li key={place_id} onClick={() => handleSelect(description)}>
              {description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};



const fetchAndLogUserData = async () => {
  try {
    setLoading(true);
    const userRegularDetails = await fetchRegularDetails(userId);

    
    setFullRegularDetails(userRegularDetails);

  } catch (error) {
    console.error("Error fetching user data:", error);
    toast.error("Failed to fetch user data");
  } finally {
    setLoading(false);
  }
};

React.useEffect(() => {
  fetchAndLogUserData();
  localStorage.setItem("home", "/auth/workplace");
}, []);

useEffect(() => {
  if (fullRegularDetails && fullRegularDetails.workplaces) {
    const workplace = fullRegularDetails.currentWorkplace;

    // Only set workplace if formData.location is not already set
    if (!formData.location && typeof workplace === "string") {
      const workPlaceAddress = workplace;
      if (workPlaceAddress) {
        setWorkplaceName(workPlaceAddress);
      }
    }
  }
}, [fullRegularDetails, formData.location]);





useEffect(() => {
}, [formData])

  return (
    <div className="flex flex-col gap-[16px]">
      <h1 className="text-[27px] font-[600] text-[#0F172A]">
        {title}
      </h1>
      <p className="text-[14px] text-[#64748B]">
        {description}
      </p>
      <input
        type="text"
        className="noHover !hover:none !active:none no-focus-effects h-[62px] w-full rounded-xl border-[1px] border-[#E2E8F0] px-2 font-[400]"
        placeholder="Event Name"
        value={formData.name}
        onChange={(e) => onFormChange({ name: e.target.value })}
      />

      {/* ROW */}
      <div className="flex flex-col gap-[9px] md:flex-row ">
        <DatePicker
          selected={formData.date}
          onChange={(date) => {
            if (date < new Date()) {
              alert("The selected date is in the past. Please choose a future date.");
            } else {
              onFormChange({ date });
            }
          }}
          className="noHover !hover:none !active:none no-focus-effects h-[62px] w-full rounded-xl border-[1px] border-[#E2E8F0] px-2 font-[400]"
          placeholderText="Select a date"
          dateFormat="MMMM d, yyyy"
          filterDate={(date) => date > new Date()} // Only allow future dates
        />
        {/* Turn this into a component */}
        <div className="flex h-[62px] w-full  ">
            <input
                type="time"
                id="time"
                className="flex-shrink-0 rounded-none rounded-s-lg bg-white border text-[#696969] leading-none focus:ring-blue-500 focus:border-blue-500 block text-sm border-gray-300 p-2.5"
                min="09:00"
                max="18:00"
                value={formData.time}
                required 
                onChange={(e) => {
                  const selectedTime = e.target.value;
                  const selectedDateTime = dayjs(`${dayjs(formData.date).format('YYYY-MM-DD')} ${selectedTime}`);
                  const currentDateTime = dayjs();

  
                  if (selectedDateTime.isBefore(currentDateTime)) {
                      alert("The selected time is in the past. Please choose a future time.");
                  } else {
                      onFormChange({ time: selectedTime });
                  }
              }}
          />
            <select
                id="timezones"
                name="timezone"
                className="bg-white w-full border border-s-0 border-gray-300 text-[#696969] text-sm rounded-e-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                required
            >
                <option value="America/New_York" selected>EST - GMT-5 (New York)</option>
                <option value="America/Los_Angeles">PST - GMT-8 (Los Angeles)</option>
                <option value="Europe/London">GMT - GMT+0 (London)</option>
                <option value="Europe/Paris">CET - GMT+1 (Paris)</option>
                <option value="Asia/Tokyo">JST - GMT+9 (Tokyo)</option>
                <option value="Australia/Sydney">AEDT - GMT+11 (Sydney)</option>
                <option value="Canada/Mountain">MST - GMT-7 (Canada)</option>
                <option value="Canada/Central">CST - GMT-6 (Canada)</option>
                <option value="Canada/Eastern">EST - GMT-5 (Canada)</option>
                <option value="Europe/Berlin">CET - GMT+1 (Berlin)</option>
                <option value="Asia/Dubai">GST - GMT+4 (Dubai)</option>
                <option value="Asia/Singapore">SGT - GMT+8 (Singapore)</option>
            </select>
        </div>



      </div>


     <div className="flex">
     {isLoaded && (
          <Map barState={barState} setSelectWorkplace={setSelectWorkplace} workplaceName={workplaceName}
          onFormChange={onFormChange}
          isLoaded={isLoaded}
          />
      )}
      </div>

      {/* <input
        type="text"
        className="noHover !hover:none !active:none no-focus-effects h-[62px] w-full rounded-xl border-[1px] border-[#E2E8F0] px-2 font-[400]"
        placeholder="Work Location"
        value={formData.location}
        onChange={(e) => onFormChange({ location: e.target.value })}
      /> */}

      <textarea
        className="noHover !hover:none !active:none no-focus-effects h-[140px] w-full resize-none rounded-xl border-[1px] border-[#E2E8F0] p-4 px-2 font-[400]"
        placeholder="Describe Your Event"
        value={formData.description}
        onChange={(e) => onFormChange({ description: e.target.value })}
      />

     
          <button 
            onClick={onContinue}
            className="linear  h-[70px] w-full rounded-xl bg-brand-500 px-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"          >
            Continue
          </button>
    </div>
  );
};

export default EventForm;
