import React from "react";
import GenericModal from "views/admin/default/components/GenericModal";
import { useState , useEffect} from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Link } from "react-router-dom";
import { formatDate, formatDateLong } from "utils/utils";




const EventForm3 = ({
  title,
  description,
  time,
  onContinue,
  onBack,
  modalContent,
  latitude,
  longitude,
  formData,
  onFormChange,
}) => {
  const mapContainerStyle = {
    width: "100%",
    height: "150px",
  };




  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });

  const APIKEY = "AIzaSyBKq68f-LDoW1GHbyg53382x0-p_csH38U";

  useEffect(() => {
    if (formData.location) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: formData.location }, (results, status) => {
        if (status === "OK" && results[0]) {
          const { lat, lng } = results[0].geometry.location;
          setCenter({ lat: lat(), lng: lng() });
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
          // Fallback to default center if geocoding fails
          setCenter({ lat: 38.3032, lng: -77.4605 });
        }
      });
    }
  }, [formData.location]);

  
  const convertToStandardTime = (militaryTime) => {
    const [hours, minutes] = militaryTime.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';
    const standardHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${standardHours}:${minutes} ${period}`;
  };

  return (
    <div className="flex sm:w-[340px] md:max-w-[500px] w-[340px] flex-col gap-[30px]">
      <div className="flex flex-col gap-[16px]">
        <h1 className="text-[27px] font-[600] text-[#0F172A]">{formData.name}</h1>
        <p className="text-[16px] text-[#000000]">{formatDateLong(formData.date)}</p>
        <p className="text-[16px] text-[#000000]">{convertToStandardTime(formData.time)} EST</p> 
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={14}
        >
          <Marker position={center} />
        </GoogleMap>


        <div className="flex flex-col gap-[5px]">
          <h2 className="text-[16px] font-semibold text-[#000000]">
            {formData.location}
          </h2>
          <p className="text-[14px] text-[#64748B]">
          {formData.location}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-[13px]">
        <p className="text-[16px] uppercase text-[#64748B]">Details</p>

        <p className="text-[20px] text-[#000]">
        {formData.description}
        </p>
      </div>

    </div>
  );
};

export default EventForm3;
