import Card from "components/card";
import httpService from "services/http-service";
import React, { useEffect, useState } from "react";
import { MdPeopleAlt } from "react-icons/md";
import { MdOutlinePerson } from "react-icons/md";
import GenericModal from "views/admin/default/components/GenericModal";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import QRCode from "react-qr-code";
import config from "utils/config";
import { doc, updateDoc, arrayRemove, getFirestore } from "firebase/firestore";
import { auth } from "../../../../firebase/firebase";
import { fetchRegularDetails, fetchAllRegularsDetails, fetchAllUserIds,fetchAllUserPhoneNumbers } from "utils/utils";

const RegularsTable = (props) => {
  const { regularsData } = props;
  const [regulars, setRegulars] = useState([]);
  const [totalRegulars, setTotalRegulars] = useState(0);
  const [loading, setLoading] = useState(false);
  const [QRCodeValue, setQRCodeValue] = useState("");
  const [regularsNames, setRegularsNames] = useState([]);
  

  useEffect(() => {
    // Convert regularsData object to an array
    const regularsArray = Object.values(regularsData);
    setRegulars(regularsArray);
    setTotalRegulars(regularsArray.length);

    // Extract names from the regulars array
    const names = regularsArray.map((regular) => regular.name);
    setRegularsNames(names);
  }, [regularsData]);

 

  const handleRemoveRegular = async (regularData) => {
    setLoading(true);
    const db = getFirestore();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      toast.error("You must be logged in to perform this action");
      setLoading(false);
      return;
    }

    try {
      // First, get all users with their IDs and phone numbers
      const userPhoneNumbers = await fetchAllUserPhoneNumbers();
      
      // Find the matching user object based on the regularData (ID)
      const regularUser = userPhoneNumbers.find(user => user.id === regularData);

      console.log("Regular user", regularUser);
      
      if (!regularUser || !regularUser.phoneNumber) {
        throw new Error("Could not find phone number for this regular");
      }

      // Now use the phone number for the Firestore operations
      const regularRef = doc(db, "users", regularUser.phoneNumber);

      await updateDoc(regularRef, {
        favoriteBartenederIds: arrayRemove(currentUser.phoneNumber),
      });

      const bartenderRef = doc(db, "users", currentUser.phoneNumber);
      await updateDoc(bartenderRef, {
        regulars: arrayRemove(regularData), // Keep using the ID here since that's what's stored
      });

      const updatedRegulars = regulars.filter(
        (regular) => regular.id !== regularData
      );
      setRegulars(updatedRegulars);
      setTotalRegulars(updatedRegulars.length);
      toast.success("Regular removed successfully");
    } catch (error) {
      console.error("Error removing regular:", error);
      toast.error("Failed to remove regular. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card extra={"w-full h-full p-4"}>
      <Loading loading={loading} loaderColor="#f97316" />
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Your Regulars
        </div>
        <h4 className="justify-left flex items-center text-base text-navy-700 dark:text-white">
          <MdPeopleAlt className="h-6 w-6" />
          <span className="ml-[5px]">{totalRegulars}</span>
        </h4>
      </div>

      {regulars.length === 0 ? (
        <div className="pt-[100px] pb-[100px] text-center">
          <div className="text-navy-700 dark:text-white">
            Share your code to invite your regulars
          </div>
          <div className="mt-[2rem] mr-20 flex items-center justify-center px-2">
            <QRCode
              size={180}
              fgColor="orange"
              value={
                QRCodeValue || `${config.INVITE_BASE_URL}/auth/regular-sign-up`
              }
              viewBox={`0 0 256 256`}
            />
          </div>
        </div>
      ) : (
        <div className="h-full overflow-x-scroll xl:overflow-x-hidden">
          <table
            className="mt-8 h-max w-full"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <tbody>
              {regulars.map((regular, index) => (
                <tr key={regular.id}>
                  <td className="pt-[14px] pb-3 text-[14px]">
                    <div className="flex items-center gap-2">
                      <div className={`rounded-full text-xl`}>
                        <MdOutlinePerson />
                      </div>
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {regularsNames[index] || "Unknown"}
                      </p>
                    </div>
                  </td>
                  <td className="pt-[14px] pb-3 text-[14px]">
                    <GenericModal
                      modalTrigger={
                        <button className="linear border-1 font-small mt-0 w-20 rounded-sm border-orange-500 bg-brand-100 py-[1px] text-base text-orange-500 transition duration-200 hover:bg-brand-100 active:bg-brand-100 dark:bg-brand-300 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                          Remove
                        </button>
                      }
                      modalContent={
                        <Card extra={"w-full h-full p-4"}>
                          <div className="relative flex items-center justify-between">
                            <div className="text-xl font-bold text-navy-700 dark:text-white">
                              This can't be undone. Are you sure you want to
                              continue?
                            </div>
                            <button
                              onClick={() => handleRemoveRegular(regular.id)}
                              className="linear border-1 font-small mt-0 w-20 rounded-sm border-orange-500 bg-brand-100 py-[1px] text-base text-orange-500 transition duration-200 hover:bg-brand-100 active:bg-brand-100 dark:bg-brand-300 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                            >
                              Yes
                            </button>
                          </div>
                        </Card>
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Card>
  );
};

export default RegularsTable;
