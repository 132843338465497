import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "components/header/HeaderAuthDefault";
import httpService from "services/http-service";
import { useEffect, useState } from "react";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";

import { auth, db } from "../../firebase/firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  getAuth
} from "firebase/auth";
import { fetchRegularDetails } from "../../utils/utils";
import { doc, setDoc } from "firebase/firestore";




const mask = "+1 (999) 999-9999";
const maskGenerator = createDefaultMaskGenerator(mask);

export default function SignUp() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isRegular, setIsRegular] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAge, setIsAge] = useState(false);
  const [loading, setLoading] = useState(false);
  const followId = parseInt(window.location.hash.replace("#", ""));
  const [followName, setFollowName] = useState("");
  const [followLink, setFollowLink] = useState("");
  const [isStaff, setIsStaff] = useState(false);

  const user = getAuth();



  const [token, setToken] = useState(null);
  useEffect(() => {
    const localToken = localStorage.getItem("token");
    if (localToken) {
      setToken(localToken);
    }

  async function reDirectUser() {
    if (token || user) {
      if(user.currentUser) {
      const currentUser = user.currentUser;
      const currentUserPhoneNumber = currentUser.phoneNumber

      const currentUserDetails = await fetchRegularDetails(currentUserPhoneNumber);
      console.log("currentUserDetails", currentUserDetails)

  
  


      if(currentUserDetails.isStaff === true) {


        
      navigate("/auth/events", {
        state: {
          isRegular: isRegular,
          phoneNumber: phoneNumber,
          verificationId: token,
          confirmationResult: token
        },
      });
    } else if (currentUserDetails.isRegular === true) {
      

      
      navigate("/auth/customerHome", {
        state: {
          isRegular: isRegular,
          phoneNumber: phoneNumber,
          verificationId: token,
          confirmationResult: token
        },
      });
    }
  }

  }
  }


 reDirectUser()
  }, [token]);

  useEffect(() => {
    const currentUrl = window.location.href;
    console.log(currentUrl.includes('/auth/regular-sign-up')) // Change this line


    if (currentUrl.includes('/auth/regular-sign-up')) {
      console.log("The current URL" ,currentUrl)


    const afterSignUp = currentUrl.split('/auth/regular-sign-up')[1]; // Get the part after /auth/regular-sign-up

    if (afterSignUp && afterSignUp.length > 0) {
      // There is a value after /auth/regular-sign-up
      console.log("Value after regular-sign-up:", afterSignUp);

      // Check if there are query parameters
      if (afterSignUp.startsWith('?')) {
          const queryParams = new URLSearchParams(afterSignUp);
          const inviteCode = queryParams.get('invite'); // Extract the invite code

          console.log("Invite Code:", inviteCode); // Log the invite code
          localStorage.setItem("inviteCode", inviteCode)
      }
  }


      setIsRegular(true);
      setIsStaff(false);
      localStorage.setItem("isStaff", false)
      localStorage.setItem("isRegular", true)
      console.log("afterSignUp", afterSignUp)
    
    } else if (location.pathname.includes("regular")) {
      setIsRegular(true);
      localStorage.setItem("isRegular", true)
      localStorage.setItem("isStaff", false)
      if (followId.toString() !== "NaN") {
        localStorage.setItem("followId", followId);
        setFollowLink(followId);
        fetchBartenderName();
      }
    } else {
      setIsRegular(false);
      // localStorage.setItem("isRegular", false)
      // localStorage.setItem("isStaff", true)
      if (followId.toString() !== "NaN") {
        localStorage.setItem("followId", followId);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
  }, [phoneNumber]);

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const [recaptchaReady, setRecaptchaReady] = useState(false);

  const formatAndValidatePhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters
    const digitsOnly = phoneNumber.replace(/\D/g, "");
    
    // Add the country code (+1 for US/Canada)
    const formattedNumber = "+1" + digitsOnly;
    
    // E.164 format regex
    // This regex checks for:
    // 1. A '+' at the start
    // 2. Followed by 1 or more digits
    // 3. Total length between 8 and 15 characters (including the '+')
    const e164Regex = /^\+[1-9]\d{7,14}$/;
    
    if (e164Regex.test(formattedNumber)) {
      // console.log("Phone number is valid E.164 format:", formattedNumber);
      return formattedNumber;
    } else {
      console.error("Invalid phone number format:", formattedNumber);
      return null;
    }
  };

  const handleLogin = async () => {
    if (fullName && phoneNumber) {
      setLoading(true);
      try {
        if (!recaptchaReady) {
          // console.log("Configuring reCAPTCHA");
          // Configure reCAPTCHA if not already done
          if (!window.recaptchaVerifier) {
            // console.log("Creating new RecaptchaVerifier");
            window.recaptchaVerifier = new RecaptchaVerifier(
              auth,
              'recaptcha-container',
              {
                size: 'normal',
                hl: "en",
                callback: () => {
                  // console.log("reCAPTCHA callback triggered");
                  setRecaptchaReady(true);
                  handleLogin(); // Call handleLogin again after reCAPTCHA is ready
                },
                'expired-callback': () => {
                  toast.error("reCAPTCHA expired. Please try again.");
                  setLoading(false);
                }
              },
              auth
            );
          }
          
          // Render the reCAPTCHA
          window.recaptchaVerifier.render();
          return; // Exit the function and wait for the callback
        }
  
        const formattedPhoneNumber = formatAndValidatePhoneNumber(phoneNumber);

        if (!formattedPhoneNumber) {
          throw new Error("Invalid phone number format");
        }

        const appVerifier = window.recaptchaVerifier;


      // Send SMS
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        formattedPhoneNumber,
        appVerifier
      );

      window.confirmationResult = confirmationResult;
      // console.log("SMS sent successfully, confirmationResult:", confirmationResult);
      localStorage.setItem('confirmationResult', JSON.stringify(confirmationResult));


      // Store user details in localStorage
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          phoneNumber: formattedPhoneNumber,
          fullName: fullName,
        })
      );

      toast.success("Verification code sent successfully", formattedPhoneNumber);
      localStorage.setItem('verificationId', confirmationResult.verificationId);
      localStorage.setItem("Name", fullName)

      // // Navigate to verification page
      navigate("/auth/verify", {
        state: {
          isRegular: isRegular,
          isStaff: isStaff, 
          fullName: fullName,
          phoneNumber: formattedPhoneNumber,
          verificationId: confirmationResult.verificationId,
          confirmationResult: confirmationResult,
          Name: fullName
        },
      });
    } catch (error) {
      setLoading(false);
      console.error("Error during phone authentication:", error);
      toast.error("Error sending verification code: " + error.message);
    }
  } else {
    toast.error("Please fill all required fields.");
  }
};



const handleValidation = () => {
  if (fullName !== "" && phoneNumber.length === 10 && isAge === true) {
    return false;
  } else {
    return true;
  }
};

const fetchBartenderName = async () => {
  setLoading(true);
  const endpoint = `/bartenders/getBartenderName/${followId}`;
  const response = await httpService("GET", endpoint);
  if (response.Error === "HttpException") {
    setLoading(false);
    toast.error(response.Message);
  } else {
    setLoading(false);
    setFollowName(response);
  }
};

return (
  <div className="flex h-full w-full justify-center px-2 pt-6 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
    {/* <Loading loading={loading} loaderColor="#f97316" /> */}
    {/* Sign Up Section */}
    <div className="mt-[0vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
      <Header
        tagline={
          isRegular
            ? `Welcome in! ${followName && "You are following..."}`
            : "See your regulars more regularly"
        }
      />
      {followName && (
        <h4 className="mb-10 mt-[-30px] text-2xl font-bold text-navy-700 dark:text-white">
          {followName}
        </h4>
      )}
      <h4 className="mb-2.5 text-2xl font-bold text-navy-700 dark:text-white">
        Sign Up
      </h4>
      <p className="mb-1 ml-1 text-base dark:text-white">
        We'll send a confirmation code to this number to verify your account
      </p>
      {/* Name */}
      <InputField
        variant="auth"
        extra="mt-4"
        label=""
        placeholder="Full Name"
        value={fullName}
        onChange={(event) => setFullName(event.target.value)}
        id="email"
        type="text"
        pattern="[A-Za-z ]+"
      />
      {/* Phone Number */}
      <MaskedInput
        className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
        placeholder="Phone Number"
        inputMode="numeric"
        onKeyDown={handleKeyDown}
        maskGenerator={maskGenerator}
        value={phoneNumber}
        onChange={(event) => setPhoneNumber(`${event}`)}
      />
      {/* Checkbox */}
      <div className="mb-4 flex items-center justify-between px-2">
        <div className="mt-4 flex items-center">
          <Checkbox onChange={(event) => setIsAge(event.target.checked)} />
          <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
            I confirm I'm over 18 years old
          </p>
        </div>
      </div>
      <button
        disabled={handleValidation()}
        onClick={() => handleLogin()}
        className={
          !handleValidation()
            ? "linear mt-10 w-full mb-[10px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            : "linear mb-[10px] mt-10 w-full cursor-not-allowed rounded-xl bg-gray-500 py-[12px] text-base font-medium text-white opacity-50 transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        }
      >
        Send Verification Code
      </button>

      <div 
      className = "my-[10px]"
      id="recaptcha-container"></div>
      <h4 className="mt-5 items-center justify-center font-bold text-navy-700 dark:text-white">
        &nbsp; Already have an account?{" "}
        <button
          className="text-brand-500"
          onClick={() =>
            handleRouteClick(
              isRegular
                ? `/auth/regular-sign-in/#${followLink}`
                : "/auth/sign-in"
            )
          }
        >
          {" "}
          &nbsp; Sign In{" "}
        </button>
      </h4>
      <div className="signIn-notes mt-8">
        <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
          We'll send you a verification code by SMS. Standard rates may apply.
          By continuing, I agree to Gliddy's
        </span>
        <a
          href="https://meetgliddy.com/terms-of-service"
          className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
        >
          Terms of Service.
        </a>
        <span className=" ml-1 text-sm font-medium text-navy-700 dark:text-gray-600">
          Please see Gliddy's
        </span>
        <a
          href="https://meetgliddy.com/privacypolicy"
          className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
        >
          Privacy Notice
        </a>
        <span className=" ml-1 text-sm font-medium text-navy-700 dark:text-gray-600">
          regarding its privacy practies.
        </span>
      </div>
    </div>
  </div>
);
}
