import React, { useEffect, useState } from "react";
import { MdOutlinePeopleAlt, MdOutlineSettings } from "react-icons/md";
import WorkplaceCard from "views/admin/default/components/WorkplaceCard";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";
import GenericModal from "views/admin/default/components/GenericModal";
import { RegularcolumnsData } from "views/admin/tables/variables/columnsData";
import RegularsTable from "views/admin/tables/components/RegularsTable";
import { useNavigate } from "react-router-dom";
import logo from "assets/img/logo/logo.svg";
import {
  getLocalStorageValue,
  fetchUserRegulars,
  fetchAllRegularsDetails,
  countRegulars,
  fetchRegularDetails,
  toggleUserWorkingStatus,
} from "utils/utils";


export default function Workplace() {
  const [totalRegulars, setTotalRegulars] = React.useState(0);
  const [regulars, setRegulars] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [workplaceName, setWorkplaceName] = useState("");

  const [fullRegularDetails, setFullRegularDetails] = React.useState([]);

  const currentWorkplace = getLocalStorageValue("userDetails", "currentWorkplace");
  const userId = getLocalStorageValue("userDetails", "phoneNumber");
  const navigate = useNavigate();

  const fetchAndLogUserData = async () => {
    try {
      setLoading(true);
      const userRegulars = await fetchUserRegulars(userId);
      const userRegularDetails = await fetchRegularDetails(userId);
      fetchRegularDetails(userId);
      //Seteing the full reg detauls aswell
      setFullRegularDetails(userRegularDetails);

      setRegulars(userRegulars);
      setTotalRegulars(userRegulars.length);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchAndLogUserData();
    localStorage.setItem("home", "/auth/workplace");
  }, []);

  useEffect(() => {

    if (fullRegularDetails && fullRegularDetails.workplaces) {
      // console.log("HYE NOW FULL REGULAR DETAILS IS", fullRegularDetails)
      const workplace = fullRegularDetails.currentWorkplace;
      
      console.log("HYE NOW WORKPLACE IS", workplace)

        if(workplace){
          setWorkplaceName(workplace)
          navigate("/auth/workplace-home")
        }else {
          navigate("/auth/workplace")
        }
      
      // if(typeof workplace === 'object' && !Array.isArray(workplace)){
      //   const workPlaceAdress = workplace.address;
      //   if(workPlaceAdress){ 
      //     setWorkplaceName(workPlaceAdress);
      //     navigate("/auth/workplace-home")
      //   }else {
      //     navigate("/auth/workplace")
      //   }
        
        
      // }
      // setWorkplaceName(`${workplace.Name}`);
    }
  }, [fullRegularDetails]);

  useEffect(() => {
  }, [workplaceName])

  React.useEffect(() => {
    if (regulars.length > 0) {
      const fetchRegularsDetails = async () => {
        const regularNames = await fetchAllRegularsDetails(regulars);
        // console.log("All regular names:", regularNames);
      };
      fetchRegularsDetails();
      
      const count = countRegulars(regulars);
      setTotalRegulars(count);
    }
  }, [regulars]);

  React.useEffect(() => {
  }, [totalRegulars]);

  const handleRouteClick = (page) => {
    navigate(page);
  };

  return (
    <div>
      <div className="flex justify-center px-2 pt-6 max-w-[500px] w-[500px] m-auto lg:mb-10 lg:justify-start">
        <Loading loading={loading} loaderColor="#f97316" />
        {/* Bartender Welcome section */}
        <div className="w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <div className="flex h-20 w-full justify-center">
            <h4 className="absolute top-0 left-[35px] right-[19px] mt-[32px] flex justify-between text-2xl font-bold text-navy-700 dark:text-white">
              <div className="flex flex-col justify-between">
                <img
                  className="mt-2 mr-[-10px] h-[25px] w-[80px]"
                  src={logo}
                  alt="logo"
                />

                <GenericModal
                  modalTrigger={
                    <button className="justify-left mt-[8px] mb-12 flex text-[16px] font-[400] text-navy-700 dark:text-white">
                      <MdOutlinePeopleAlt className="mr-2 h-6 w-6" />
                      {totalRegulars}{" "}
                      {totalRegulars === 1 ? "Regular" : "Regulars"}
                    </button>
                  }
                  modalContent={
                    <RegularsTable columnsData={RegularcolumnsData} regularsData={regulars} />
                  }
                />
              </div>
              <div className="flex justify-between self-start">
                <button
                  onClick={() => handleRouteClick("/auth/settings")}
                  className="!linear mr-2 mr-[2px] flex justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100  dark:bg-navy-900 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                >
                  <MdOutlineSettings className="h-6 w-6" />
                </button>
              </div>
            </h4>
          </div>
          <WorkplaceCard />
        </div>
      </div>
    </div>
  );
}
