import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import routes from "routes.js";
import { useEffect, useState } from "react";
import Verify from "views/auth/Verify";
import Settings from "views/auth/Settings";

import { fetchRegularDetails } from "utils/utils";
import { getAuth } from "firebase/auth";
export default function Auth() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [isStaff, setIsStaff] = useState(true);
  const [isWorking, setIsWorking] = useState(null);
  const [regularDetails, setRegularDetails] = useState(null);
  const location = useLocation();
  const auth = getAuth();
  let userName = null;
  if (auth.currentUser) {
    userName = auth.currentUser.phoneNumber;
  }




  useEffect(() => {
    if(location.pathname === "/auth/customerHome"){
      setIsWorking(true)
      localStorage.setItem("isWorking", "true")  
    }
    
  }, [])


 useEffect(() => {
  // console.log("Logging the testingof the reg", isWorking)
 }, [isWorking])

  useEffect(() => {
    setIsAuthenticated(true)
    const localStorageValue = localStorage.getItem("isWorking") === "true";
    
    // setIsWorking(localStorageValue ?? regularDetails?.isWorking);
    setIsWorking(regularDetails?.isWorking ?? localStorageValue);
  }, [token]);


  useEffect(() => {
    if (userName) {
      fetchRegularDetails(userName).then(details => {
        setRegularDetails(details);
        if (details?.isWorking !== undefined) {
          setIsWorking(details.isWorking);
          localStorage.setItem("isWorking", String(details.isWorking));
        }
      });
    }
  }, [userName, location.pathname]);

  useEffect(() => {


  }, [regularDetails]);


  const home = localStorage.getItem("home");
  const defaultPath = home || "/auth/workplace";





  

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        if (
          prop.path === "landing" ||
          prop.path === "default" ||
          prop.path === "sign-in" ||
          prop.path === "sign-up" ||
          prop.path === "regular-sign-in" ||
          prop.path === "regular-sign-up" ||
          prop.path === "verify" ||
          prop.path === "events" ||
          prop.path === "manage-events"
        ) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
        } else {
          return (
            <Route
              path={`/${prop.path}`}
              element={
                !isAuthenticated ? (
                  <Navigate to={"/auth"} replace />
                ) : (
                  prop.component
                )
              }
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };

  // Helper function to check if we're on a sign-in page
  const isSignInPage = () => {
    return location.pathname === '/auth/sign-in' || 
           location.pathname === '/auth/regular-sign-in' || location.pathname === '/auth/sign-up' || location.pathname === '/auth/regular-sign-up' || location.pathname === "/auth/verify" || location.pathname === "/auth/customerHome" || location.pathname === "/auth/welcome"
  };

  document.documentElement.dir = "ltr";
  return (
    <div>
      
      <div className={`relative float-right h-full min-h-screen w-full ${
        isSignInPage() ? 'bg-white' : // Always white for sign-in pages
        isWorking ? 'bg-white' : 'bg-navy-900 text-white'
      }`}>
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full  w-full flex-col justify-start pt-0 md:max-w-[75%] lg:h-screen lg:max-w-[1013px]  lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 ">
              <div
                className={
                  "mb-auto flex max-h-[100vh] overflow-x-hidden overflow-y-auto flex-col m:pr-5 md:pr-5 md:pl-5  lg:pl-0 xl:max-w-full"
                }
              >
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={() => {
           
                      return isAuthenticated && isStaff
                        ? <Navigate to={defaultPath} replace />
                        : <Navigate to="/auth/sign-in" replace />;
                    }}
                  />
                  <Route
                    path={`/verify`}
                    element={
                      <Verify
                        isAuthenticated={isAuthenticated}
                        setIsAuthenticated={setIsAuthenticated}
                      />
                    }
                  />
                  <Route
                    path={`/settings`}
                    element={isAuthenticated ?
                      <Settings
                        isAuthenticated={isAuthenticated}
                        setIsAuthenticated={setIsAuthenticated}
                      /> : <Navigate to="/auth/sign-in" replace />
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
