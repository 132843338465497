import { RegularcolumnsData } from "views/admin/tables/variables/columnsData";
import FollowingsTable from "views/admin/tables/components/FollowingsTable";
import iosadd from "../../assets/img/icons/iosadd.svg";

export default function CustomerFollowings({
  workingFollowings = [],
  offWorkFollowings = [],
  handleRemoveFollower,
  setLoading
}) {



  // console.log("workingFollowings", workingFollowings)
  // console.log("offWorkFollowings", offWorkFollowings)   
// console.log("handleRemoveFollower", handleRemoveFollower)

  return (
    <div className="mt-[-54px] flex h-full overflow-y-scroll scrollbar-hide w-full justify-center md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      <div className="w-full max-w-full flex-col justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Bartender List
        </h4>
        <h4 className="mt-1 mb-10 align-text-center text-sm text-gray-700 dark:text-white">
          Tap the <img className="inline pr-1 pl-1 pb-2" src={iosadd} alt="iosadd"/> in the address bar to add to your home screen for faster access.
        </h4>
        <div className="pb-[300px]">
        {workingFollowings && workingFollowings.length > 0 && (
          <>
            <FollowingsTable
              handleRemoveFollower={handleRemoveFollower}
              columnsData={RegularcolumnsData}
              rowsData={workingFollowings}
              setLoading={setLoading}
              title={"Working"}
            />
            <div className="mb-8"></div>
          </>
        )}
        {offWorkFollowings && offWorkFollowings.length > 0 && (
          <>
            <FollowingsTable
              handleRemoveFollower={handleRemoveFollower}
              columnsData={RegularcolumnsData}
              rowsData={offWorkFollowings}
              title={"Off Work"}
              setLoading={setLoading}
            />{" "}
          </>
        )}
        </div>
      </div>
    </div>
  );
}
