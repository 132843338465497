import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import {
  getLocalStorageValue,
  formatDate,
  getLocalStorageObject,
  fetchUserRegulars, 
  fetchAllRegularsDetails, 
  countRegulars 
} from "utils/utils";


const EventForm2 = ({
  title,
  description,
  onContinue,
  onBack,
  modalContent,
  formData,
  onFormChange,
}) => {
  const [isChecked, setIsChecked] = useState(false);


  const toggleWarning = () => { 
    if(!formData?.name || !formData?.date || !formData?.time){
     toast.error("Please fill in all the fields");
  
   
   }else { 
   }
  }
 
 


useEffect(() => { 
  toggleWarning()

}, [formData?.name, formData?.date, formData?.time])






    // Schedule an SMS event reminder 
    // const scheduleEventReminder = async (event) => {
    //   try {
    //     // Convert event date and time to a timestamp
    //     const [month, day, year] = event.date.split(/[,\s]+/).filter(Boolean);
    //     const [time, period] = event.time.split(/\s+/);
        
    //     const eventDateTime = new Date(`${month} ${day} ${year} ${time} ${period}`);
    //     const reminderTime = new Date(eventDateTime.getTime() - (24 * 60 * 60 * 1000)); // 24 hours before
    
    //     // Create the reminder message
    //     const message = templateMessage
    //       .replace("${userName}", userName)
    //       .replace("${eventLocation}", event.location)
    //       .replace("${eventName}", event.name)
    //       .replace("${eventTime}", event.time)
    //       .replace("${eventDescription}", event.description)
    //       .replace(/\\n/g, "\n");
    
    //     // Schedule the reminder by sending to your backend
    //     const response = await fetch(
    //       "https://b1muqhevw3.execute-api.us-east-1.amazonaws.com/dev/GliddyFunction", // You'll need to create this endpoint
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //           senderId: userId,
    //           phoneNumbers: regularPhoneNumbers,
    //           message: message,
    //           eventId: event.id,
    //           scheduledTime: reminderTime.toISOString(),
    //           reminderEvent: true
    //         }),
    //       }
    //     );
    
    //     if (response.ok) {
    //       toast.success("Reminder scheduled successfully");
    //     } else {
    //       toast.error("Failed to schedule reminder");
    //     }
    //   } catch (error) {
    //     console.error("Error scheduling reminder:", error);
    //     toast.error("Error scheduling reminder");
    //   }
    // };





  const handleToggle = () => {
    setIsChecked(!isChecked);
    onFormChange({ smsReminder: !isChecked });

    console.log(formData.smsReminder);
  };



  return (
    <div className="flex flex-col gap-[30px]">
      <div className="flex flex-col gap-[16px]">
        <h1 className="text-[27px] font-[600] text-[#0F172A]">{title}</h1>
      </div>
      <div className="flex flex-col gap-[13px]">
        <h1 className="text-[24px] font-[600] text-[#0F172A]">
          Enable SMS reminder to regulars
        </h1>
        <p className="text-[20px] text-[#64748B]">
          Send 24hours before the event.
        </p>
      </div>
      <div className="flex flex-col gap-[13px]">
        <h1 className="text-[24px] font-[600] text-[#0F172A]">
          Enable SMS reminder to regulars “Maybe”
        </h1>
        <p className="text-[20px] text-[#64748B]">
          Send 2 days before the event.
        </p>
      </div>
      <div className="toggle-container flex-row gap-[10px]">
        <div className="labels gap-[10px]">
          <span className="font-medium text-[#6B7280]">Off</span>

          <label className="switch">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleToggle}
              value={formData.smsReminder}
            />
            <span className="slider relative z-[5] flex items-start justify-start px-[5px] text-white">
              <span className="relative z-[5] flex items-center justify-center">
                <svg
                  viewBox="0 0 24 24"
                  // fill={isChecked ? "black" : "white"}
                  // color={isChecked ? "black" : "white)"}
                  height="2em"
                  aria-hidden="true"
                  focusable="false"
                  tabindex="-1"
                  className="h-[20px] w-[20px] rotate-45"
                >
                  <path
                    d="M12 4v8m0 0v8m0-8H4m8 0h8"
                    stroke={isChecked ? "white" : "black"}
                    stroke-linecap="round"
                    stroke-width="2"
                  ></path>
                </svg>
              </span>
              <span className="switch-icon relative z-[5] flex items-center justify-center !text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.736 3.97C12.8043 3.90037 12.8857 3.84505 12.9756 3.80729C13.0655 3.76952 13.162 3.75007 13.2595 3.75007C13.357 3.75007 13.4535 3.76952 13.5434 3.80729C13.6333 3.84505 13.7148 3.90037 13.783 3.97C14.069 4.259 14.073 4.726 13.793 5.02L7.88001 12.01C7.81288 12.0837 7.73141 12.143 7.64059 12.1841C7.54977 12.2253 7.45151 12.2475 7.35182 12.2493C7.25213 12.2512 7.1531 12.2327 7.0608 12.195C6.9685 12.1573 6.88487 12.1012 6.81501 12.03L3.21701 8.384C3.07825 8.24249 3.00052 8.0522 3.00052 7.854C3.00052 7.65581 3.07825 7.46552 3.21701 7.324C3.28526 7.25437 3.36671 7.19905 3.4566 7.16129C3.54649 7.12352 3.64301 7.10407 3.74051 7.10407C3.83801 7.10407 3.93453 7.12352 4.02442 7.16129C4.11431 7.19905 4.19576 7.25437 4.26401 7.324L7.31601 10.417L12.716 3.992C12.7222 3.98427 12.7289 3.97693 12.736 3.97Z"
                    fill={isChecked ? "#3B82F6" : "white"}
                  />
                </svg>
              </span>
              <span
                className={`z-1 absolute h-[32px] w-[32px] rounded-full bg-white transition-transform duration-300 ease-in-out`}
                style={{
                  transform: isChecked
                    ? "translateX(27px)"
                    : "translateX(-4px)",
                  left: "4px",
                }}
              ></span>
            </span>
          </label>
          <span className="font-medium text-[#6B7280]">On</span>
        </div>
      </div>

      {/* {isChecked && (
        <div className="flex flex-col gap-[13px]">
          <h2 className="text-[20px] font-[600] text-[#0F172A]">
            Send SMS to Regulars
          </h2>
          <p className="text-[16px] text-[#64748B]">
            {regularPhoneNumbers.length} regular(s) will receive the SMS.
          </p>
          <button
            onClick={sendSMS}
            disabled={isSending || regularPhoneNumbers.length === 0}
            className="mt-2 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 disabled:bg-blue-300"
          >
            {isSending ? 'Sending...' : 'Send SMS to Regulars'}
          </button>
          {smsStatus && (
            <p className={`mt-2 ${smsStatus.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
              {smsStatus}
            </p>
          )}
        </div>
      )} */}

      <div className="mt-[50px] flex flex-col gap-[10px]">
        <button
          onClick={onContinue}
          disabled={!formData?.name || !formData?.date || !formData?.time}
          className={`linear h-[70px] w-full rounded-xl px-2 text-base font-medium text-white transition duration-200 ${
            !formData?.name || !formData?.date || !formData?.time
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          }`}
        >
          Continue
        </button>

        <button
          onClick={onBack}
          className="linear text-black text-black  bg-transparent mb-5 h-[55px] w-full rounded-xl border-[2px] border-[#000]  px-2 text-base font-medium"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default EventForm2;
