import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import PropTypes from "prop-types";
import { FaTimes } from "react-icons/fa";

const GenericModal = ({ modalTrigger, modalContent, modalSuppress = null }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <div onClick={!modalSuppress ? onOpen : null}>{modalTrigger}</div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] bg-opacity-50" />
        <ModalContent className="!m-auto !w-max min-w-[85%] !max-w-[85%] md:top-[20vh]">
          <ModalHeader
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            className="mt-[-15px]"
          >
            <ModalCloseButton
              color={document.body.classList.contains("dark") ? "white" : "black"}
              size="lg"
              borderRadius="50%"
              marginTop={100}
              marginRight={20}
              position={"absolute"}
              zIndex={1000}
              _hover={{ bg: "gray.500" }}
              _active={{ bg: "gray.600" }}
              _focus={{ boxShadow: "none" }}
              icon={<FaTimes />}
              className="!pt-4 !pb-0"
            />
          </ModalHeader>
          <ModalBody>{modalContent}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

GenericModal.propTypes = {
  modalTrigger: PropTypes.element,
  modalContent: PropTypes.element.isRequired,
};

export default GenericModal;
