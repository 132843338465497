import React, { useState, useEffect } from "react";
import Card from "components/card";
import httpService from "services/http-service";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";

import {
  getLocalStorageValue,
  fetchUserRegulars,
  fetchAllRegularsDetails,
  countRegulars,
  fetchRegularDetails,
  toggleUserWorkingStatus,
} from "utils/utils";

import { auth } from "../../../../firebase/firebase"
import { getFirestore, doc, setDoc } from "firebase/firestore";


const MyWorkplaces = ({ type = "", headerTitle = "", buttonText = "", setWorkplaceFunction, workPlaceName, onClose }) => {


  const [isSending, setIsSending] = useState(false);
  const [smsStatus, setSmsStatus] = useState(null);
  const [regularPhoneNumbers , setRegularPhoneNumbers] = useState([])


  const [totalRegulars, setTotalRegulars] = useState(0);
  const [regulars, setRegulars] = useState([]);
  const [regularDetails, setRegularDetails] = useState([]);
  const [fullRegularDetails, setFullRegularDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isWorking, setIsWorking] = useState(true);
  const [workplaces, setWorkplaces] = useState([]);
  const [previousWorkplaceName, setPreviousWorkplaceName] = useState(workPlaceName); // New state for previous workplace name


  const userId = getLocalStorageValue("userDetails", "phoneNumber");




  const fetchAndLogUserData = async () => {
    try {
      setLoading(true);
      const userRegulars = await fetchUserRegulars(userId);

      const userRegularDetails = await fetchRegularDetails(userId);
      fetchRegularDetails(userId);
      //Seteing the full reg detauls aswell
      setFullRegularDetails(userRegularDetails);
      setIsWorking(userRegularDetails.isWorking);

      

      setRegulars(userRegulars);
      setTotalRegulars(userRegulars.length);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndLogUserData();
    localStorage.setItem("home", "/auth/workplace-home");
    localStorage.setItem("isWorking", isWorking);
  }, [userId, isWorking]);


  // React.useEffect(() => {


  //   if (regulars.length > 0) {
  //     const fetchRegularsDetails = async () => {
  //       const regularNames = await fetchAllRegularsDetails(regulars);
  //       setRegularDetails(regularNames);


  //       console.log("regularNames", regularNames)


  //       debugger
  //       if(regularNames.length > 0){    
  //         const phoneNumbers = regularNames.map(regular => regular.phoneNumber).filter(Boolean);
  //         setRegularPhoneNumbers(phoneNumbers);
  //       }

  //     };
  //     fetchRegularsDetails();
      
  //     const count = countRegulars(regulars);
  //     setTotalRegulars(count);
  //   }
  // }, [regulars, totalRegulars]);


  useEffect(() => { 

  }, [regularPhoneNumbers])




  useEffect(() => {
    if (fullRegularDetails && fullRegularDetails.workplaces) {
      const workplace = fullRegularDetails.workplaces;

      setWorkplaces(workplace);

      if (typeof workplace === "object" && !Array.isArray(workplace)) {
        const workPlaceAdress = workplace.address;
        if (workPlaceAdress) {
          // Split the string by commas and trim whitespace
          const parts = workPlaceAdress.split(",").map((part) => part.trim());

          // Get the first part, which is "Evening Bar"
          const firstEveningBar = parts[0];
          // setWorkplaceName(firstEveningBar);
        } else {
        }
      }
      // setWorkplaceName(`${workplace.Name}`);
    }
  }, [fullRegularDetails]);



  useEffect(() => {
    // console.log("workplaceName", workplaces)
  }, [workplaces])  



  const sendSmsToRegulars = async (smsMessage) => {



    try {
      const response = await fetch('https://lr81vgidw9.execute-api.us-east-1.amazonaws.com/prod', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          senderId: userId,
          phoneNumbers: regularPhoneNumbers,
          message: smsMessage ,
        }),
      });

      const result = await response.json();
      
      if (response.ok) {
        setSmsStatus('SMS sent successfully to all recipients');
      } else {
        setSmsStatus(`Failed to send SMS: ${result.message || 'Unknown error'}`);
      }
    } catch (error) {
      setSmsStatus(`Error: ${error.message}`);
    } finally {
      setIsSending(false);
    }  }

  // const messageRegulars = async () => {
  //   // Logic to send SMS to regulars
  //   const smsMessage = `Hey guys its me, ${userName}, currently working at ${workPlaceName}.`;
  //   // Assuming you have a function to send SMS, e.g., sendSmsToRegulars(smsMessage);



    
  //   await sendSmsToRegulars(smsMessage);
  //   console.log("Message sent to regulars:", smsMessage);
  // };

  const handleSave = async () => {
  

    // Check if the workplace name has changed
    if (workPlaceName !== previousWorkplaceName) {
      // await messageRegulars(); // Call messageRegulars if the workplace name has changed
      
      onClose();
      // Update current workplace in Firestore
      const db = getFirestore(); // Initialize Firestore
      const userRef = doc(db, "users", userId); // Reference to the user's document

      // Update the currentWorkPlace field in Firestore
      await setDoc(userRef, { currentWorkPlace: workPlaceName }, { merge: true }); // Update current workplace
      toast.success("Workplace updated");
    } else {
      onClose()
      console.log("Workplace name remained the same");
    }
  }
  const handleSelectWorkplace = (workplace) => {
    setWorkplaceFunction(workplace.address);
    localStorage.setItem("userObject", "currentWorkplace");
  };

  return (
    <Card extra={"mt-3 mb-10 !z-5"}>
      <Loading loading={loading} loaderColor="#f97316" />
      {/* MyWorkplaces Header */}
      <div className="flex items-center justify-between rounded-t-3xl p-3">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          {headerTitle}
        </div>
      </div>

      {/* History CardData */}

      {/* Mapping through the workplaces array to display each address */}
      <div className="list-scroll px-2">
        {workplaces.map((workplace, index) => (
          <div
            key={index} // Use index as the key if Id is not available
            className={`flex h-full w-full items-start justify-between overflow-auto bg-white px-3 py-[10px] text-navy-700 dark:!bg-navy-800 dark:text-white dark:shadow-none md:overflow-scroll text-left transition-all duration-300 rounded-[5px] ${
              JSON.parse(localStorage.getItem("currentWorkplace")) === workplace.address
                ? "border-brand-500 border-2"
                : "hover:border-brand-500 hover:border-2 dark:hover:border-brand-500"
            }`}
          >
            <a
              className="list-border active"
              onClick={() => handleSelectWorkplace(workplace)} // Pass the entire workplace object
            >
              <div className="m-[10px] flex items-center gap-3">
                <div className="flex h-16 w-16 items-center justify-center">
                  <img
                    className="h-full w-full rounded-xl"
                    src={workplace.ImageURL} // Ensure ImageURL exists in your data
                    alt=""
                  />
                </div>

                <div className="flex w-56 flex-col">
                  <p className="mt-1 text-sm font-normal">{workplace.address}</p> {/* Display the address */}
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>

      <div className="flex items-center justify-between">
        <button
          onClick={() => handleSave()}
          disabled={!workPlaceName}
          className={
            workplaces.length > 0
              ? "linear w-90 m-4 mt-5 mb-5 w-full rounded-lg bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              : "linear w-90 m-4 mt-5 mb-5 w-full cursor-not-allowed rounded-lg bg-brand-500 py-[12px] text-base font-medium text-white opacity-50 transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          }
        >
          {" "}
          {buttonText}
        </button>
      </div>
    </Card>
  );
};

export default MyWorkplaces;
