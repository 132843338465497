import InputField from "components/fields/InputField";
import React, { useState, useEffect } from "react";
import {
  useNavigate,

} from "react-router-dom";
import { toast } from "react-toastify";
import { getLocalStorageValue, getLocalStorageObject } from "utils/utils";
import Header from "components/header/HeaderAuthDefault";
import Loading from "react-fullscreen-loading";
import { mask, createDefaultMaskGenerator } from "react-hook-mask";
import {
  getAuth,
  PhoneAuthProvider,
  signInWithCredential,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import {auth} from "../../firebase/firebase";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

const maskFormat = "+1 (999) 999-9999";
const maskGenerator = createDefaultMaskGenerator(maskFormat);

export default function Verify({
  phoneNumber = getLocalStorageValue("userDetails", "phoneNumber"),
  ...props
}) {
  const navigate = useNavigate();
  const [verCode, setVerCode] = useState("");
  const [loading, setLoading] = useState(false);
  //Temp set these isreuglar based on the URL link on this link is the staff
  const staff =
    getLocalStorageValue("userDetails", "isStaff") ||
    localStorage.getItem("isStaff");
  const regular =
    getLocalStorageValue("userDetails", "isRegular") ||
    localStorage.getItem("isRegular");
  const [isRegular, setIsRegular] = useState(null);
  const [isStaff, setIsStaff] = useState(null);



  const Name = localStorage.getItem("Name");
  const [resendTimer, setResendTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    document.getElementById("vercode").focus();

    if (staff === "true") {
      setIsStaff(true);
    } else if (regular === "true") {
      setIsRegular(true);
    }
  }, []);

  useEffect(() => {}, [isRegular, isStaff]);

  useEffect(() => {
    // Start timer when component mounts
    let interval = null;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [resendTimer]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleVerifyCode();
    }
  };

  const handleVerifyCode = async () => {
    console.log("isRegular on verification  ", isRegular);
    console.log("isStaff on verification ", isStaff);

    if (verCode) {
      try {
        setLoading(true);

        // Get the confirmation result from localStorage
        const storedData = JSON.parse(
          localStorage.getItem("confirmationResult")
        );
        const verificationId = storedData.verificationId;

        if (!verificationId) {
          throw new Error("Verification ID not found");
        }

        // Create a PhoneAuthCredential with the code
        const auth = getAuth();
        const credential = PhoneAuthProvider.credential(
          verificationId,
          verCode
        );

        // Sign in with the credential
        const result = await signInWithCredential(auth, credential);

        // User signed in successfully
        const user = result.user;

        // Get the ID token
        const token = await user.getIdToken();

        // Save the token to localStorage
        localStorage.setItem("token", token);
        props.setIsAuthenticated(true);

        // Check if user exists in Firestore
        const db = getFirestore();
        const userRef = doc(db, "users", phoneNumber);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
          // Generate a UUID for the new user
          const userId = crypto.randomUUID();

          // If the user doesn't exist, create a new document
          const userData = {
            id: userId, // Add the UUID here
            phoneNumber: phoneNumber,
            name: Name,
            isRegular: isRegular,
            isStaff: isStaff,
            createdAt: new Date(),
            updatedAt: new Date(),
            // Common fields for both user types
            email: "",
            profilePicture: "",
            // Fields for regular users
            ...(isRegular && {
              regularsCount: 0,
              favoriteBarIds: [],
              favoriteBartenederIds: [],
              visitedBarIds: [],
              orderHistory: [],
            }),
            // Fields for bartenders
            ...(isStaff && {
              workplaces: [],
              currentWorkPlace: "",
              bio: "",
              specialties: [],
              ratings: [],
              averageRating: 0,
              isVerified: false,
              isWorking: true,
              yearsOfExperience: 0,
              shifts: [],
              regulars: [],
            }),
          };

          // If the user doesn't exist, create a new document
          await setDoc(userRef, userData);

          localStorage.setItem("userDetails", JSON.stringify(userData));
        } else {
          // If the user exists, retrieve the existing data and ensure it has an id
          const existingUserData = userSnap.data();
          if (!existingUserData.id) {
            existingUserData.id = crypto.randomUUID();
            await setDoc(userRef, existingUserData, { merge: true });
          }
          localStorage.setItem("userDetails", JSON.stringify(existingUserData));
        }

        setLoading(false);
        toast.success("Verification successful!");

        // Navigate the user to the next page
        if (token) {
          navigateUser();
        }
      } catch (error) {
        setLoading(false);
        console.error("Verification error:", error);
        toast.error(
          "Verification failed. Please check your code and try again."
        );
      }
    }
  };

  const navigateUser = async () => {
    const token = localStorage.getItem("token");
    if (isRegular) {
      JSON.parse(localStorage.getItem("userDetails")).isRegular == false
        ? navigate("/auth/welcome")
        : navigate("/auth/customerHome");
    } else {
      JSON.parse(localStorage.getItem("userDetails")).isStaff == true
        ? navigate("/auth/workplace")
        : navigate("/auth/workplace-home");
    }
  };

  const handleSendCodeAgain = async () => {
    if (!canResend) return;
    
    try {
      setLoading(true);
      
      // Get the stored phone number
      const userPhoneNumber = getLocalStorageObject("userDetails", "phoneNumber");
      
      // Recreate the reCAPTCHA verifier
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          'recaptcha-container',
          {
            size: 'invisible',
            callback: () => {},
          }
        );
      }

      const appVerifier = window.recaptchaVerifier;
      
      // Send new SMS
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        userPhoneNumber,
        appVerifier
      );

      // Store new confirmation result
      localStorage.setItem('confirmationResult', JSON.stringify(confirmationResult));
      
      // Reset timer and disable resend
      setResendTimer(60);
      setCanResend(false);
      setLoading(false);
      toast.success("New verification code sent!");
      
    } catch (error) {
      setLoading(false);
      console.error("Error resending code:", error);
      toast.error("Failed to send new code. Please try again.");
    }
  };

  const onInputChange = (e) => {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setVerCode(e.target.value);
    }
  };

  useEffect(() => {}, [verCode]);

  return (
    <div className="flex h-full w-full justify-center px-2 pt-6 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      <Loading loading={loading} loaderColor="#f97316" />
      <div className="mt-0 w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <Header />
        <h4 className="mb-2.5 text-2xl font-bold text-navy-700 dark:text-white">
          Enter Verification Code
        </h4>
        <p className="text-base dark:text-white">
          Code has been sent to{" "}
          <span className="font-bold text-brand-500 hover:text-brand-600 dark:text-white">
            {mask(phoneNumber, maskGenerator)}
          </span>
        </p>

        {/* otp */}
        <div className="mt-9">
          <InputField
            index="1"
            variant=""
            extra="mb-1 mx-1"
            inputStyle="text-center font-lightbold !text-xl"
            label=""
            id="vercode"
            type="text"
            pattern="\d"
            inputmode="numeric"
            value={verCode}
            onChange={(event) => onInputChange(event)}
            maxLength={6}
            onKeyDown={handleKeyDown}
          />
        </div>
        {/* Send Again */}
        <div className="flex items-center justify-between px-2">
          <div className="flex items-center"></div>
        </div>
        <div>
          <div className="flex h-[50px] w-full items-center justify-center gap-2 rounded-xl font-bold">
            <div className="rounded-full text-xl"></div>
            <button
              onClick={handleSendCodeAgain}
              disabled={!canResend}
              className={`font-bold ${
                canResend 
                  ? "text-brand-500 hover:text-brand-600 cursor-pointer" 
                  : "text-gray-400 cursor-not-allowed"
              } dark:text-white`}
            >
              {canResend 
                ? "Send another code" 
                : `Resend code in ${resendTimer}s`
              }
            </button>
          </div>

          <div id="recaptcha-container"></div>

          <button
            disabled={verCode.length !== 6}
            onClick={() => handleVerifyCode()}
            className={
              verCode.length === 6
                ? "linear mt-20 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                : "linear mt-20 w-full cursor-not-allowed rounded-xl bg-gray-500 py-[12px] text-base font-medium text-white opacity-50 transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-gray-300 dark:active:bg-gray-200"
            }
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
