import React from "react";
import InputField from "components/fields/InputField";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import httpService from "services/http-service";
import { getLocalStorageValue, getLocalStorageObject } from "utils/utils";
import Loading from "react-fullscreen-loading";
import { useNavigate, useLocation } from "react-router-dom";
import Sheet from "react-modal-sheet";
import { MdOutlineArrowBack } from "react-icons/md";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";
import Verify from "./Verify";
import Card from "components/card";
import { updateUserName } from "utils/utils";
const mask = "+1 (999) 999-9999";
const maskGenerator = createDefaultMaskGenerator(mask);

export default function Editprofile() {
  const navigate = useNavigate();
  const location = useLocation();
  const prevNumber = getLocalStorageValue("userDetails", "phoneNumber");
  const prevName = getLocalStorageValue("userDetails", "name");
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(prevNumber);
  // const [phoneNumber, setPhoneNumber] = useState("4239335112");
  const [isRegular, setIsRegular] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fullName, setFullName] = useState(prevName);
  const userDetails = {
    ...JSON.parse(localStorage.getItem("userDetails")),
  };
  const userId = getLocalStorageValue("userDetails", "phoneNumber");

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  useEffect(() => {
    if (location.pathname.includes("regular")) {
      setIsRegular(true);
    } else {
      setIsRegular(false);
    }
  }, [location.pathname]);




  const handleUpdateProfile = async () => {
    setLoading(true);

    if (updateUserName(userId, fullName)) {
      // Update userObject after successfully updating the name
      const userObject = getLocalStorageObject("userDetails");
      userObject.name = fullName; // Update the name in userObject
      localStorage.setItem("userDetails", JSON.stringify(userObject)); // Save updated userObject back to local storage

      toast.success("Profile updated successfully");
      setFullName("");
      setLoading(false);
    } else {
      toast.error("Failed to update profile");
      setLoading(false);
    }
  };

  const handleVerifyNumber = () => {
    handleUpdateProfile();
    setIsOpen(true);
  };

  return (
    <div
      className="
    m-auto flex h-full w-[500px]
    max-w-[500px] justify-center px-2  pt-6 sm:w-[100%] md:mx-0 md:px-0 lg:mb-10 lg:justify-start"
    >
      <Loading loading={loading} loaderColor="#f97316" />
      {/* Edit Profile section */}
      <div className="mt-[0vh] w-full max-w-full  flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <button
          onClick={() => handleRouteClick("/auth/settings")}
          className="absolute text-navy-700 dark:text-white"
        >
          <MdOutlineArrowBack className="mt-[4px] ml-[5px] h-8 w-8" />
        </button>
        <h4 className="align-center mb-[37px] mt-[5px] flex justify-center text-xl font-bold text-navy-700 dark:text-white">
          Edit profile
        </h4>

        <InputField
          variant="auth"
          extra=""
          label=""
          placeholder={`${prevName}`}
          id="email"
          value={fullName}
          onChange={(event) => setFullName(event.target.value)}
          type="text"
        />
        <MaskedInput
          className="mt-2 mb-[27px] flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 p-3 text-sm outline-none dark:!border-white/10 dark:text-navy-700"
          placeholder="Phone Number"
          maskGenerator={maskGenerator}
          value={phoneNumber}
          pattern="\d*"
          inputMode="numeric"
          onChange={(event) => setPhoneNumber(`${event}`)}
        />
        <div className="flex items-center justify-between px-2">
          <div className="flex items-center"></div>
        </div>
        {`+1${phoneNumber}` === prevNumber ? (
          <button
            onClick={fullName !== prevName ? () => handleUpdateProfile() : null}
            className={
              fullName !== prevName
                ? "linear w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                : "linear w-full cursor-not-allowed rounded-xl bg-gray-500 py-[12px] text-base font-medium text-white opacity-50 transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            }
          >
            Save changes
          </button>
        ) : phoneNumber.length === 10 ? (
          <button
            onClick={() => handleVerifyNumber()}
            className="linear w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Send verification code
          </button>
        ) : (
          <button className="linear w-full cursor-not-allowed rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white opacity-50 transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Send verification code
          </button>
        )}
        <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <Card>
                <Verify phoneNumber={`+1${phoneNumber}`} />
              </Card>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      </div>
    </div>
  );
}
