import { MdQrCodeScanner } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import httpService from "services/http-service";
import {
  getLocalStorageValue,
  formatDate,
  getLocalStorageObject,
} from "utils/utils";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import HeaderEvent from "components/header/HeaderEvent";
import EventForm from "components/events/evenform";
import EventForm2 from "components/events/eventform2";
import EventForm3 from "components/events/eventform3";

import { useParams } from "react-router-dom";


import { fetchEventDetailsById } from "utils/utils";


import { doc, updateDoc } from "firebase/firestore";
import {db} from '../../../../firebase/firebase'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { LoadScript } from "@react-google-maps/api"; // Import LoadScript

import { formatDateLong } from "utils/utils";
export default function EditEventId() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);




  const userId = getLocalStorageValue("userDetails", "isStaff");

  const APIKEY = "AIzaSyBKq68f-LDoW1GHbyg53382x0-p_csH38U";

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: APIKEY,
    libraries: ["places"], // Ensure 'places' is included
  });

  //checking for current user 

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    console.log("Logging the userAuth", auth)
    console.log("Auth state changed. Current user:", user);
    
    // Cleanup subscription on unmount
  }, []);
  


  //Fetching the event details
  useEffect(() => {
    const fetchEventDetails = async () => { 
      const returnEventDetails = await fetchEventDetailsById(id);
      setEventDetails(returnEventDetails);

    // Temporarily set eventDetails to fetched details as placeholders
    
    if (returnEventDetails) {
      setEventData({
        name: returnEventDetails.title || "",
        date: convertFirestoreTimestampToDate(returnEventDetails.date) || "", // Convert date
        time: returnEventDetails.time || "",
        location: returnEventDetails.location || "",
        description: returnEventDetails.description || "",
        smsReminder: returnEventDetails.smsReminder || false,
      });
    }

    console.log("Event details", returnEventDetails);
  };
  fetchEventDetails();
  }, []);

  //Send ussers who are not staff back to regular-sign in our wherever
  useEffect(() => {
    if (!userId) {
      navigate("/auth/regular-sign-in");
    }
  }, []);

  //Capturing the event data

  const [eventData, setEventData] = useState({
    name: "",
    date: "",
    time: "",
    location: "",
    description: "",
    smsReminder: false,
  });


  
  //Submit the form placeholder
  const handleFormChange = (newData) => {
    setEventData((prevData) => ({ ...prevData, ...newData }));
    console.log("Event data:", eventData);
  };


  // ... existing imports and code ...
  
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", eventData);
  
    try {
      if (!userId) {
        throw new Error("No authenticated user found");
      }
  
      setLoading(true);
  
      // Ensure the date is in the correct format (YYYY-MM-DD)
      const formattedDate = formatDate(eventData.date);
      console.log("Formatted date:", formattedDate);
  
      // Ensure the time is in the correct format (HH:mm)
      const formattedTime = eventData.time.padStart(5, '0');
      console.log("Formatted time:", formattedTime);
  
      // Combine date and time
      const dateTimeString = `${formattedDate}T${formattedTime}:00`;
      console.log("Date-time string:", dateTimeString);
  
      const eventRef = doc(db, "events", id);
      console.log("Event reference:", eventRef);
  
      const updatedEvent = {
        title: eventData.name,
        description: eventData.description,
        date: new Date(dateTimeString),
        location: eventData.location,
        smsReminder: eventData.smsReminder,
      };
  
      await updateDoc(eventRef, updatedEvent);
      console.log("Event updated with ID:", id);
      console.log("Updated event data:", updatedEvent);
  
      setLoading(false);
      navigate('/auth/events');
    } catch (error) {
      console.error("Error updating event:", error);
      setLoading(false);
      // Consider adding a toast notification here for the error
      toast.error("Failed to update event. Please try again.");
    }
  };
  


  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    // Initialize with your form fields
    title: "",
    description: "",
    date: "",
    // Add more fields as needed
  });
  const onContinue = () => {
    if (currentStep < 3) {
      // Move to the next step
      setCurrentStep(currentStep + 1);
      //   toast.info(`Moved to step ${currentStep + 1}`);
    } else {
      // Final step: log form data and reset
      //   toast.success('Event created successfully! (Dummy action)');
      // Reset form
      setFormData({
        title: "",
        description: "",
        date: "",
        // Reset other fields
      });
      setCurrentStep(1);
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {}, [currentStep]);

  const handleContinue = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
    onContinue();
  };


  const [delayedLoad, setDelayedLoad] = useState(false); // New state for delayed loading

  useEffect(() => {
    if (isLoaded) {
      const timer = setTimeout(() => {
        setDelayedLoad(true); // Set delayedLoad to true after 3 seconds
      }, 3000);

      console.log("delayedLoad", isLoaded)
      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [isLoaded]);

  useEffect(() => {
    console.log("delayedLoad", delayedLoad)
  }, [delayedLoad])

  return (
    <div className="m-auto w-[340px] md:max-w-[500px]  ">
    {!delayedLoad && <Loading loading={!delayedLoad} loaderColor="#f97316" />}
    {!isLoaded ? (
      <Loading loading={true} loaderColor="#f97316" /> // Show loading until Google Maps is loaded
    ) : (
      <>
        <div className="flex flex-col gap-[13px]">
          <HeaderEvent regHome={false} regCreate={true} />
          <ProgressBar currentStep={currentStep} />
        </div>

        {delayedLoad && currentStep === 1 && ( // Use delayedLoad instead of isLoaded
          <EventForm
            title={"Describe your event"}
            description={"Describe your event and share with regulars instantly"}
            onContinue={handleContinue}
            onBack={handleBack}
            formData={eventData}
            onFormChange={handleFormChange}
            isLoaded={isLoaded}
            APIKEY={APIKEY}
          />
        )}
        {currentStep == 2 && (
          <EventForm2
            title={"Do you want to send auto-rem inders to regulars via SMS?"}
            description={"Tuesday, May 5, 2020"}
            onContinue={handleContinue}
            onBack={handleBack}
            formData={eventData}
            onFormChange={handleFormChange}
          />
        )}
        {currentStep == 3 && (
          <>
            <EventForm3
              title={"Trivia Hawaii Night"}
              description={"Tuesday, May 5, 2020"}
              time={"6:00 PM"}
              onContinue={handleContinue}
              onBack={handleBack}
              formData={eventData}
              onFormChange={handleFormChange}
              latitude={37.7749} // Replace with actual latitude
              longitude={-122.4194} // Replace with actual longitude
              isLoaded={isLoaded}
            />

<div className="mt-[50px] flex flex-col gap-[10px]">
                <button
                  onClick={handleSubmitForm}
                  className="linear  h-[70px] w-full rounded-xl bg-brand-500 px-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Finish and Send Invites
                </button>
                <button
                  onClick={handleBack}
                  className="linear text-black text-black  bg-transparent mb-5 h-[55px] w-full rounded-xl border-[2px] border-[#000]  px-2 text-base font-medium"
                >
                  Back
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

const ProgressBar = ({ currentStep }) => {
  const getProgressWidth = () => {
    switch (currentStep) {
      case 1:
        return "10%";
      case 2:
        return "50%";
      case 3:
        return "100%";
      default:
        return "0%";
    }
  };

  return (
    <div className="mb-6 h-2 w-full rounded-full bg-white">
      <div
        className="h-full rounded-full bg-brand-500 transition-all duration-300 ease-in-out"
        style={{ width: getProgressWidth() }}
      ></div>
    </div>
  );
};

// Utility function to convert Firestore timestamp to readable date
const convertFirestoreTimestampToDate = (timestamp) => {
  if (!timestamp || !timestamp.seconds) return "";
  const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
  return formatDateLong(date); // Use your existing formatDateLong function
};
