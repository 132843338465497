import Card from "components/card";
import WorkplaceList from './WorkplaceList'
import { MdErrorOutline } from "react-icons/md";
import Sheet from "react-modal-sheet";
import { useState } from "react";
import { LoadScript } from '@react-google-maps/api';


const WorkplaceCard = () => {
  const [isOpen, setIsOpen] = useState(false);




  // Add your Google Maps API key here
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;

  return (
    <Card extra="flex flex-col max-w-[300px] w-full rounded-2xl py-8 px-8 text-center mt-5 border border-navy-700 dark:!border-white">
      <MdErrorOutline className="h-9 w-9" />
      <div className="md:mt-16 lg:mt-0">
        <div className="h-dull w-full xl:h-[350px]">
          <h4 className="justify-left mt-3 flex text-xl font-bold text-navy-700 dark:text-white">
            Add your workplace
          </h4>
          <h4 className="justify-left mt-3 flex text-left text-base text-navy-700 dark:text-white">
            Once you add a workplace you'll be able to allow regulars to join
            your list.
          </h4>
          <button
            onClick={() => setIsOpen(true)}
            className="linear mt-8 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Add your workplaces
          </button>
          <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <Sheet.Container>
              <Sheet.Header />
              <Sheet.Content>
                  <WorkplaceList
                    type="'Find'"
                    headerTitle="Where do you work?"
                    buttonText="Done"
                  />
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
          </Sheet>
        </div>
      </div>
    </Card>
  );
};

export default WorkplaceCard;
