import React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import EventCard from "views/auth/events/components/EventCard";
// Compoonents that we will be using
import HeaderEvent from "components/header/HeaderEvent";
// import ArrowLeft from "assets/img/icons/arrow-left.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "assets/img/icons/ArrowLeft";
//timestamp

import dayjs from "dayjs";
import { analyzeImage } from "./lib/computervision";
import {
  getLocalStorageValue,
  getLocalStorageObject,
  fetchUserRegulars,
  fetchAllRegularsDetails,
  countRegulars,
} from "utils/utils";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { db } from "../../../firebase/firebase";
import Loading from "react-fullscreen-loading";

// Initialize Firebase

import * as tf from '@tensorflow/tfjs';
import * as cocossd from '@tensorflow-models/coco-ssd';
import Webcam from "react-webcam";

const ManageEventsPage = ({}) => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);

  const [regulars, setRegulars] = useState(0);
  const [totalRegulars, setTotalRegulars] = React.useState(0);
  const [regularDetails, setRegularDetails] = useState([]);

  const [numberOfEvents, setNumberOfEvents] = useState(null);

  const userDetails = localStorage.getItem("userDetails");
  const userObject = userDetails
    ? getLocalStorageObject("userDetails") ?? null
    : null;

  const userId = getLocalStorageValue("userDetails", "phoneNumber");

  const navigate = useNavigate();

  const [adminuserId, setAdminuserId] = useState("+14239335112");

  const videoRef = useRef(null);
  const [imageDataUrl, setImageDataUrl] = useState(null);

  const [azureResult, setAzureResult] = useState(null);
  const [agentEventData, setAgentEventData] = useState(null);

  const [model, setModel] = useState(null);
  const [isDocumentDetected, setIsDocumentDetected] = useState(false);
  const [cameraActive, setCameraActive] = useState(false);

  const canvasRef = useRef(null);

  useEffect(() => {
    if (regulars.length > 0) {
      const fetchRegularsDetails = async () => {
        const regularDetails = await fetchAllRegularsDetails(regulars);
        setRegularDetails(regularDetails);
      };
      fetchRegularsDetails();

      const count = countRegulars(regulars);
      setTotalRegulars(count);
    }
  }, [regulars]);

  useEffect(() => {}, [regularDetails]);

  const fetchAndLogUserData = async () => {
    try {
      // setLoading(true);
      const userRegulars = await fetchUserRegulars(userId);

      setRegulars(userRegulars);
      setTotalRegulars(userRegulars.length);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchAndLogUserData();
    localStorage.setItem("home", "/auth/workplace");
  }, []);

  React.useEffect(() => {
    if (regulars.length > 0) {
      const fetchRegularsDetails = async () => {
        const regularNames = await fetchAllRegularsDetails(regulars);
      };
      fetchRegularsDetails();

      const count = countRegulars(regulars);
      setTotalRegulars(count);
    }
  }, [regulars, totalRegulars]);

  //Firebase configuration

  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch users from Firestore
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, "users");
        const querySnapshot = await getDocs(usersCollection);
        const usersData = [];
        querySnapshot.forEach((doc) => {
          usersData.push({ id: doc.id, ...doc.data() });
        });

        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {

    const fetchEvents = async () => {
      if (!userObject || !userObject.phoneNumber) {
        console.error("User ID not found");
        return;
      }

      try {
        const eventsRef = collection(db, "events");

        const q = query(
          eventsRef,
          where("createdBy", "==", userObject.phoneNumber)
        );
        const querySnapshot = await getDocs(q);

        const fetchedEvents = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.title,
            date: data.date.toDate().toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }),
            time: data.date.toDate().toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            }),
            description: data.description,
            location: data.location,
            responseRate: data.responseRate || 0,
            invited: data.goingNumber || 0,
            going: data.going || 0,
            maybe: data.maybeNumber || 0,
            notGoing: data.notGoingNumber || 0,
          };
        });


        setEvents(fetchedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
      }
    };

    fetchEvents();
  }, []);

  const calculateNumberOfEvents = (events) => {
    return events.length;
  };

  useEffect(() => {
    const eventCount = calculateNumberOfEvents(events);
    // console.log("This is the event count", eventCount)
    setNumberOfEvents(eventCount);
    // console.log("This is the events", events)
  }, [events]);

  const [isWorking, setIsWorking] = useState(() => {
    const stored = localStorage.getItem("isWorking");
    return stored === "true"; // Convert string to boolean
  });
  useEffect(() => {
    const localStorageValue = localStorage.getItem("isWorking") === "true";
    setIsWorking(regularDetails?.isWorking ?? localStorageValue);
  }, [regularDetails]);

  useEffect(() => {
  }, [isWorking]);

  const redirectToCreateEvent = () => {
    if (isWorking) {
      navigate("/auth/create-event");
    } else {
      toast.warning("You are not working");
    }
  };

  //FUnction to use Camera

  // Scanning flyer
  const handleScan = async () => {
    try {
      setIsLoading(true);
      
      // Load COCO-SSD model
      if (!model) {
        const loadedModel = await cocossd.load();
        setModel(loadedModel);
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { 
          facingMode: { ideal: "environment" },
          width: { ideal: 1920 },
          height: { ideal: 1080 }
        }
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
        
        // Set up canvas dimensions
        const videoWidth = videoRef.current.videoWidth;
        const videoHeight = videoRef.current.videoHeight;
        
        if (canvasRef.current) {
          canvasRef.current.width = videoWidth;
          canvasRef.current.height = videoHeight;
        }
        
        setCameraActive(true);
        detectObjects();
      }
    } catch (error) {
      console.error("Camera access error:", error);
      toast.error("Failed to access camera");
    } finally {
      setIsLoading(false);
    }
  };

  const stopCamera = useCallback(() => {
    if (videoRef.current?.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
    setCameraActive(false);
    setIsDocumentDetected(false);
  }, []);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      stopCamera();
    };
  }, [stopCamera]);

  const takePicture = () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext("2d");

      if (context) {
        try {
          // Draw the video frame to canvas
          context.drawImage(
            videoRef.current,
            0,
            0,
            canvas.width,
            canvas.height
          );

          // Convert to base64 data URL
          const imageDataUrl = canvas.toDataURL("image/jpeg", 0.8); // Added quality parameter

          // Log the data for debugging
          // console.log("Generated Camera Image Data:", {
          //   length: imageDataUrl.length,
          //   startsWith: imageDataUrl.substring(0, 50),
          //   isValidFormat: imageDataUrl.startsWith("data:image/"),
          //   hasBase64: imageDataUrl.includes("base64"),
          //   dimensions: `${canvas.width}x${canvas.height}`,
          // });

          // Validate the format
          if (!imageDataUrl.startsWith("data:image/")) {
            throw new Error("Invalid image format generated");
          }

          // Stop the video stream after capturing
          const stream = videoRef.current.srcObject;
          if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
            videoRef.current.srcObject = null;
          }

          setImageDataUrl(imageDataUrl);
          handleFlyerAnalysis(imageDataUrl);
        } catch (error) {
          console.error("Error capturing image:", error);
          toast.error("Failed to capture image. Please try again.");
        }
      }
    } else {
      console.error("Video reference not available");
      toast.error("Camera not ready. Please try again.");
    }
  };

  function parseEventResponse(response) {
    const lines = response.split("\n");
    const eventData = {
      date: "",
      time: "",
      location: null,
      description: "",
      title: "",
    };

    lines.forEach((line) => {
      if (line.includes("Date:")) {
        eventData.date = line.split("Date:")[1].trim();
      } else if (line.includes("Time:")) {
        eventData.time = line.split("Time:")[1].trim();
      } else if (line.includes("Location:")) {
        const location = line.split("Location:")[1].trim();
        eventData.location = location !== "Not provided" ? location : null;
      } else if (line.includes("Description:")) {
        eventData.description = line.split("Description:")[1].trim();
      } else if (line.includes("Title:")) {
        eventData.title = line.split("Title:")[1].trim();
      }
    });

    return eventData;
  }

  const handleFlyerAnalysis = async (imageDataUrl) => {
    if (imageDataUrl) {
      setIsLoading(true);
      try {
        // 1. Get text from Azure Vision
        const azureResponse = await analyzeImage(imageDataUrl);

        if ("readResult" in azureResponse && azureResponse.readResult?.blocks) {
          const extractedText = azureResponse.readResult.blocks
            .map((block) => block.lines.map((line) => line.text).join(" "))
            .join("\n");

          // Set Azure raw result
          setAzureResult(extractedText);

          // 2. Send to our Flask backend for agent processing
          const agentResponse = await fetch(
            "https://extractgliddy-adgjh6gzfzb9bkb0.canadacentral-01.azurewebsites.net/process_text",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ ocr_text: extractedText }),
            }
          );

          if (!agentResponse.ok) {
            throw new Error("Failed to process event data");
          }

          const processedData = await agentResponse.json();

          // console.log("Processed Data", processedData)
          

          // Parse only the agent result into structured data
          const agentParsed = parseEventResponse(processedData.result);

          setAgentEventData(agentParsed);

          if (agentParsed) {
            // Convert time to 24-hour format
            const convertTo24Hour = (timeStr) => {
              if (!timeStr) return "";

              try {
                // Parse the time string
                const [time, period] = timeStr.split(" ");
                const [hours, minutes] = time.split(":");

                let hour = parseInt(hours, 10);

                // Convert to 24-hour format
                if (period === "PM" && hour !== 12) {
                  hour += 12;
                } else if (period === "AM" && hour === 12) {
                  hour = 0;
                }

                // Format as HH:mm
                return `${hour.toString().padStart(2, "0")}:${minutes}`;
              } catch (error) {
                console.error("Time conversion error:", error);
                return "";
              }
            };

            const formatDate = (dateStr) => {
              if (!dateStr) return "";
              try {
                // Convert "20th August" to a proper date format
                const date = dayjs(dateStr, ["DD MMM", "Do MMMM", "D MMMM"]);
                // Set the year to current year if not provided
                if (!date.isValid()) return "";
                const currentYear = new Date().getFullYear();
                return date.year(currentYear).toDate(); // Returns JavaScript Date object
              } catch (error) {
                console.error("Date conversion error:", error);
                return "";
              }
            };

         

            const eventFormData = {
              title: agentParsed.title,
              date: formatDate(agentParsed.date),
              // time: agentParsed.time,
              location: agentParsed.location || "",
              description: agentParsed.description,
              smsReminder: true,
            };

            setIsLoading(false);
            navigate("/auth/create-event", {
              state: { eventFormData },
            });
          }
        }
      } catch (error) {
        console.error("Error processing image:", error);
        toast.error("Failed to process flyer");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAnalyzeImage = async () => {
    if (imageDataUrl) {
      try {
        setIsLoading(true);
        // 1. Get text from Azure Vision
        const azureResponse = await analyzeImage(imageDataUrl);

        if ("readResult" in azureResponse && azureResponse.readResult?.blocks) {
          const extractedText = azureResponse.readResult.blocks
            .map((block) => block.lines.map((line) => line.text).join(" "))
            .join("\n");

          // Set Azure raw result
          setAzureResult(extractedText);

          // 2. Send to our Flask backend for agent processing
          const agentResponse = await fetch(
            "https://extractgliddy-adgjh6gzfzb9bkb0.canadacentral-01.azurewebsites.net/process_text",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ ocr_text: extractedText }),
            }
          );

          if (!agentResponse.ok) {
            throw new Error("Failed to process event data");
          }

          const processedData = await agentResponse.json();

          // Parse only the agent result into structured data
          const agentParsed = parseEventResponse(processedData.result);
          setAgentEventData(agentParsed);

          if (agentParsed) {
            // Convert time to 24-hour format
            const convertTo24Hour = (timeStr) => {
              if (!timeStr) return "";

              try {
                // Parse the time string
                const [time, period] = timeStr.split(" ");
                const [hours, minutes] = time.split(":");

                let hour = parseInt(hours, 10);

                // Convert to 24-hour format
                if (period === "PM" && hour !== 12) {
                  hour += 12;
                } else if (period === "AM" && hour === 12) {
                  hour = 0;
                }

                // Format as HH:mm
                return `${hour.toString().padStart(2, "0")}:${minutes}`;
              } catch (error) {
                console.error("Time conversion error:", error);
                return "";
              }
            };

            const formatDate = (dateStr) => {
              if (!dateStr) return "";
              try {
                // Convert "20th August" to a proper date format
                const date = dayjs(dateStr, ["DD MMM", "Do MMMM", "D MMMM"]);
                // Set the year to current year if not provided
                if (!date.isValid()) return "";
                const currentYear = new Date().getFullYear();
                return date.year(currentYear).toDate(); // Returns JavaScript Date object
              } catch (error) {
                console.error("Date conversion error:", error);
                return "";
              }
            };

         

            const eventFormData = {
              name: "Event from Flyer",
              date: formatDate(agentParsed.date),
              // time: agentParsed.time,
              location: agentParsed.location || "",
              description: agentParsed.description,
              smsReminder: true,
            };

            setIsLoading(false);

            navigate("/auth/create-event", {
              state: { eventFormData },
            });
          }
        }
      } catch (error) {
        console.error("Error processing image:", error);
        toast.error("Failed to process flyer");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Add new test function while keeping existing camera functions
  const handleTestScan = async () => {
    try {
      // Fetch the test image and convert it to base64
      const response = await fetch("http://localhost:3000/PartyFlyer.jpg");
      const blob = await response.blob();

      // Convert blob to base64 data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        console.log("Generated Test Image Data:", {
          length: base64data.length,
          startsWith: base64data.substring(0, 30),
          isValidFormat: base64data.startsWith("data:image/"),
        });

        setImageDataUrl(base64data);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error loading test image:", error);
    }
  };

  useEffect(() => {
  }, [imageDataUrl]);

  useEffect(() => {
  }, [isLoading]);

  // Add drawResults utility function
  const drawResults = (predictions, ctx) => {
    // Clear canvas
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Draw each detected object
    predictions.forEach(prediction => {
      // Get prediction results
      const [x, y, width, height] = prediction.bbox;
      const text = `${prediction.class} ${Math.round(prediction.score * 100)}%`;

      // Style for the bounding box
      ctx.strokeStyle = '#00FF00';
      ctx.lineWidth = 2;
      ctx.strokeRect(x, y, width, height);

      // Style for the text
      ctx.fillStyle = '#00FF00';
      ctx.font = '18px Arial';
      ctx.fillText(text, x, y > 10 ? y - 5 : 10);
    });
  };

  // Update detect function to include drawing
  const detectObjects = async () => {
    if (!model || !videoRef.current || !cameraActive) return;

    try {
      const predictions = await model.detect(videoRef.current);
      
      // Draw predictions on canvas
      const ctx = canvasRef.current.getContext("2d");
      drawResults(predictions, ctx);
      
      // Check for document detection
      const documentFound = predictions.some(pred => 
        ['book', 'paper', 'cell phone'].includes(pred.class) && 
        pred.score > 0.7
      );

      setIsDocumentDetected(documentFound);
      
      if (cameraActive) {
        requestAnimationFrame(detectObjects);
      }
    } catch (error) {
      console.error('Detection error:', error);
    }
  };


  const renderCameraUI = () => {
    if (!cameraActive) {
      return (
        <button
          onClick={handleScan}
          className="text-[14px] font-[400] text-[#64748B] hover:text-brand-500 transition-colors"
        >
          {"Scan Flyer"}
        </button>
      );
    }else { 
      return (
        <button
          onClick={takePicture}
          className="text-[14px] font-[400] text-[#64748B] hover:text-brand-500 transition-colors"
        >
          Analyze Flyer
        </button>
      );
    }

    return (
      <div className="fixed inset-0 z-50 bg-black">
        <div className="relative h-full">
          <video 
            ref={videoRef} 
            className="h-full w-full object-cover"
            playsInline
          />
          
          <canvas
            ref={canvasRef}
            className="absolute top-0 left-0 h-full w-full"
            style={{ objectFit: 'cover' }}
          />
          
          {isDocumentDetected && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                          text-center bg-black bg-opacity-50 p-4 rounded-lg">
              <p className="text-white mb-2">Document Detected!</p>
              <button 
                onClick={takePicture}
                className="bg-brand-500 px-6 py-2 rounded-lg text-white hover:bg-brand-600 
                         transition-colors"
              >
                Capture
              </button>
            </div>
          )}

          <button 
            onClick={stopCamera}
            className="absolute top-4 right-4 bg-black bg-opacity-50 p-2 rounded-full"
          >
            <span className="text-white">✕</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className="m-auto w-[500px] max-w-[500px] sm:w-[100%] sm:px-[10px]
    
    "
    >
      <Loading loading={isLoading} loaderColor="#f97316" />

      <HeaderEvent
        tagline="Regulars"
        regHome={false}
        regCreate={false}
        totalRegulars={totalRegulars}
      />


<Link 
        to="/auth/events"

className="flex flex-row items-center justify-start gap-[20px]">

<div className="inline-flex">

<span
        className="flex flex-row items-center justify-start gap-[20px]"
      ></span>
      <ArrowLeft />
</div>

      <p className="text-[15px] font-[600]">Back</p>

</Link>
   
      <div className="mt-[32px] flex flex-row justify-between">
        <div className="flex flex-col">
          <p className="text-[18px] font-[600]">Manage Events</p>
          <p className="text-[14px] font-[400] text-[#64748B]">
            {numberOfEvents} event{numberOfEvents === 1 ? "" : "s"}
          </p>
        </div>

    
        <div className="flex flex-row gap-[10px] items-center justify-center w-[200px]" >
        {renderCameraUI()}
          <span
            onClick={redirectToCreateEvent}
          className="linear  flex h-[46px] w-full max-w-[115px] items-center justify-center rounded-xl bg-brand-500 py-[12px] px-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          New Event
        </span>


        </div>

      
      </div>


 

      {/* {loading && (
        <Loading loading={loading} loaderColor="#f97316" />
      )} */}

      <div className="mt-[45px] flex flex-col gap-[15px]">
        <div className="relative overflow-hidden">
          <div className="flex flex-col gap-[15px]">
            {events
            .filter(event => new Date(event.date) > new Date())
              .sort((a, b) => new Date(a.date) - new Date(b.date))
              .map((event, index) => (
                <div key={event.id} className="flex-shrink-0 ">
                  <EventCard
                    event={event}
                    regularDetails={regularDetails}
                    loading={isLoading}
                    setLoading={setLoading}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="video-container">
        <video ref={videoRef} className="video" />
      </div>

  

      {/* Show image preview */}
      {imageDataUrl && (
        <div className="mt-4">
          <img src={imageDataUrl} alt="Scanned flyer" className="max-w-full" />
        </div>
      )}

      {/* Structured Agent Result */}
      {agentEventData && (
        <div>
          <h3 className="mb-2 text-lg font-bold">AI Agent Analysis:</h3>
          <div className="mt-4 ">
            <p>Date: {agentEventData.date}</p>
            <p>Time: {agentEventData.time}</p>
            {agentEventData.location && (
              <p>Location: {agentEventData.location}</p>
            )}
            <p>Description: {agentEventData.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageEventsPage;
